$black: #444444;
$marginPadding: 20px;
$orange: #f0762b;

@import "../color_pallet.scss";
@import "../common_style.scss";

.ongoingJourney {
  // background-image: url("/images/dark_map.png");
  background-color: #000000;
  padding: 0px 0px 30px 100px;
  height: 432px;
  position: relative;
}
.ongoingJourney > h1 {
  margin: 0px 0px 30px 0px;
  text-align: left;
  color: #ffffff;
  display: inline-block;
  @include BS_semi_bold;
}
.j_address_div > p {
  @include BSC_extra_bold;
  font-size: 18px;
}
.scroll_icon {
  display: inline-block;
  float: right;
  margin-right: 30px;
}
.scroll_icon > img {
  transform: rotate(-90deg);
  cursor: pointer;
}
.widget {
 
 padding: 44px 8px;
 overflow: scroll;
 // margin-top: 55px;
}
.journeyDiv {
  text-align: left;
  display: inline-block;
  background-color: #ffffff;
  padding: $marginPadding;
  border-radius: 20px;
  margin-right: 20px;
  width: 23vw;
}

.people_journeyDiv {
  text-align: left;
  display: inline-block;
  background-color: #ffffff;
  padding: $marginPadding;
  border-radius: 20px;
  margin-right: 20px;
  width: 30vw;
  text-align: center;
}

.m_wrap {
  width: 100%;
  height: 100px;
}
.dx {
  width: 100%;
  white-space: nowrap;
  text-align: justify;
  overflow-x: hidden;
  padding-bottom: 10px;
}
.custom-hr {
  margin-left: -$marginPadding;
  margin-right: -$marginPadding;
}
.journey_status {
  text-transform: uppercase;
  @include work_sans_regular14;
  font-size: 10px;
}
.j_vehicle_name,
.j_driver_name {
  @include BSC_semi_bold16;
}
.arrow_button {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-image: linear-gradient(to bottom, #ed7d28, #ff3501);
  float: right;
  text-align: center;
  box-shadow: 0 0 10px 0 rgba(244, 129, 31, 0.6);
  cursor: pointer;
}
.arrow_button > i {
  position: relative;
  top: 2px;
  color: #ffffff;
}


.widgetComponent {
 // overflow: hidden;
 overflow: scroll;
}


// .widgetComponent:hover{
//   overflow: auto;
// }



.nj_content {
  color: #ffffff;
}
.nj_image > img {
  display: block;
  margin: auto;
  width: 70px;
  height: 70px;
}
.nj_parent {
  border: 1px dashed #828282;
  margin-right: 100px;
  padding: 55px 10px;
  border-radius: 10px;
}
.nw_parent {
  height: 62vh;
  border: 1px dashed #d4d4d4;
  padding: 10px;
  border-radius: 10px;
  position: relative;
}
.na_parent {
  box-shadow: 0px 0px 10px 2px #f3f3f3;
  padding: 10px;
  border-radius: 10px;
  height: 62vh;
  position: relative;
}
.nw_add_widget {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-image: linear-gradient(#f37425, #fe450c);
  display: block;
  margin: auto;
  cursor: pointer;
}
.nw_add_widget_parent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.na_blank_div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.nw_add_widget_parent > p {
  margin: 10px;
}
.na_blank_div > img {
  display: block;
  margin: auto;
  width: 60px;
  height: 60px;
}
.half_widget {
  height: 25em;
}
.full_widget {
  height: 50em;
}
.ld_2_widgets:first-child {
  margin-bottom: 50px;
}

.people_tracking_icon {
  height: fit-content;
  width: fit-content;
  display: block;
  margin: auto;
  background-color: white;
  cursor: pointer;
  margin-top: -13px;
}
.people_tracking_icon > img {
  width: 50px;
  display: block;
  margin: auto;
  padding-top: 5px;
}
.ppl_loading {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #ffffff;
  top: 0;
  border-radius: 18px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.short_username{
    white-space: nowrap;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}