$orangeColor : #f7691f;

@mixin bg_white_border_none{
  background-color: #ffffff;
  border: none;
  outline: 0;
}

.react-calendar__month-view__days>button[disabled],
.react-calendar__year-view__months>button[disabled],
.react-calendar__decade-view__years>button[disabled]{
  @include bg_white_border_none;
  color: #c7c7c7;
}

.react-calendar__year-view__months>button,
.react-calendar__decade-view__years>button{
  @include bg_white_border_none;
  border: 0.5px solid #c7c7c7;
  padding: 10px;
}

.react-calendar__month-view__days>button,
.react-calendar__navigation>button
{
  @include bg_white_border_none;
}
.react-calendar__month-view__days>button{
    width: 2.8em !important;
    height: 2.8em !important;
    padding: 0px !important;
    outline: none;
}
.react-calendar__tile--active{
  background-image: linear-gradient(#fe450c, #f37425) !important;
  outline: none;
  color: #ffffff;
  opacity: 0.9;
}
.calendar-component{
  padding: 1em;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 0px #d8d8d8;
  background-color: #ffffff;
}
.react-calendar__month-view__weekdays>div{
  color: $orangeColor;
}
.react-calendar__month-view__weekdays>div>abbr[title]{
  cursor: default;
  text-decoration: none;
}
.react-calendar__viewContainer{
  margin-top: 10px;
}
