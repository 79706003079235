.statistic_container{
    position: fixed;
    z-index: 99999;
    bottom: 30px;
    right: 20px;
    width: 300px;
    background: #ffffff;
    padding: 10px;
    box-shadow: 0px 0px 15px 0px #cccccc;
    border-radius: 8px;
    text-align: center;
}
.search_area{
    position: fixed;
    z-index: 99999;
    top: 100px;
    left: 20px;
    width: 280px;   
    box-shadow: 0px 0px 15px 0px #cccccc;             
}
.search_vehicle_name{
    position: fixed;
    z-index: 99999;
    top: 100px;
    left: 320px;
    width: 300px;
    padding: 8px 25px 0px 25px;   
    box-shadow: 0px 0px 15px 0px #cccccc;  
    background-color: #ffffff;
}
.current_notification{
    position: fixed;
    z-index: 99999;
    top: 100px;
    right: 20px;
    width: 50px;
    height: 50px;
    background: #ffffff;
    padding: 10px;
    box-shadow: 0px 0px 15px 0px #cccccc;
    border-radius: 50%;
    cursor: pointer;    
}
.open_win_icon{
    position: absolute;
    top: -20px;
    right: 47%;
}
.slide_win{
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    max-height: 250px;
    overflow-y: auto;
    top: 0;    
    transition: max-height 0.7s;
}
.slide_win.close {
    top: 100%;
    max-height: 0;
    border : none;        
  }
.port_name{
    margin: 0px;
    text-align: left;
    line-height: 45px;
    margin-left: 10px;
}
.port_panel{
    margin-bottom: 15px;
    border: 1px solid #ccc;
    cursor: pointer;
}
.search_list{
    background: #ffffff;
    padding: 10px;
    display: none;   
    max-height: 200px;
    overflow-y: auto; 
}
.notification-icon{
    display: block;
    margin: auto;
    width: 25px;
}
.notification_count{
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: #F0762B;
    color: #ffffff;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    font-weight: 700;    
    text-align: center;
    line-height: 22px;
}
.jsw_report_parent,
.jws_report{
    padding-left: 100px;
    padding-right: 100px;    
}
.all_port{
    margin: 0;
    padding: 5px;
    font-weight: 700;
    color: #fff;
    cursor: pointer;
    background-image: linear-gradient(to bottom, #ed7d28, #ff3501);
}
.legend_text{
    margin-left: 5px;
    margin-bottom: 0px;
    text-align: left;
    margin-top: 3px
}
.legend_1{
    height: 10px;
    width: 10px;
    background-color: green;
}
.legend_2{
    height: 10px;
    width: 10px;
    background-color: yellow;
}
.legend_3{
    height: 10px;
    width: 10px;
    background-color: red;
}
.legend_parent{
    height: 100%;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dedede;
}
.justify_center{
    display: flex;
    justify-content: center;
    align-items: center;
}

.legend_parent1{
    height: 100%;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    }

.statistic_container1{


    position: absolute;
    z-index: 99999;
    bottom: 5px;
    // right: 120px;
    width: auto;
    margin-bottom: 5px;
    height: auto;
    background: whitesmoke;
    padding: 7px;
    box-shadow: 0px 0px 10px 0px #cccccc;
   // border-radius: 18px;
    text-align: center;
    opacity: 1;
    left: 20px;
    opacity: 11;
}


.legend_4{
    height: 15px;
    width: 15px;
    background-color: #3326EB;
}
.legend_5{
    height: 15px;
    width: 15px;
    background-color:#DE0291;
}
.legend_6{
    height: 15px;
    width: 15px;
    background-color : rgb(179, 174, 174);
}
.legend_7{
    height: 15px;
    width: 15px;
    background-color: #F28A3A;
}


@media (max-width: 768px) {
    .statistic_container3 {
       margin-bottom: 201px;
      //  margin-bottom: 149px;
    }
}



@media (max-width: 768px) {
    .statistic_container1 {
        margin-bottom: 490px;
    }
}