// $orange : #F0762B;
// @import "../common_style";

// .navBar{
//   padding: 18px;
//   padding-bottom: 0px;
//   background-color: #000000;
//   position: fixed;
//   z-index: 99999;
//   box-shadow: 0px 0px 10px 2px #424242;
// }
// .navBar-align-img{
//   text-align: left;
// }
// .navBar-align-username{  
//   color: #F0762B;
//   @include BS_semi_bold;
//   font-size: 20px;
// }
// .navBar-list{
//   color: #ffffff;
//   font-weight: bold;
//   font-size: 16px;
//   cursor: pointer;
//   height: 38px;
// }
// .navBar-borderBottom{
//   border-bottom: 5px solid $orange;
//   color: #F0762B;
// }
// .navBar-click{
//   color: #ffffff;
//   @include BC_medium20;
// }
// .navBar-click:focus{
//   color: $orange;
//   text-decoration: none;
// }
// .navBar-click:hover{
//   color: currentColor;
//   text-decoration: none;
// }
// .is-active{
//   color: $orange;
// }
// .is_active{
//   color: currentColor !important;
//   text-decoration: none;
// }
// .classic_login{
//   position: absolute;    
//     bottom: -35px;
//     background: #0a0a0a;    
//     padding: 5px 25px;
//     color: #ffffff;
//     /* left: 31em; */
//     left: 0;
//     border-radius: 0px 0px 10px 10px;
//     border: 1px solid #4b4b4b;
// }


/* NavbarComponent.scss */

.navbar-dark {
  background-color: #000;
}
.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}
.grid-container {
  display: flex;
  align-items: center;
}

.nav-links {
  display: flex;
  color: #fff;
  justify-content: center;
  width: 100%;

  
}

.nav-link {
  font-size: 16px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  transition: color 0.3s ease;

  
}
.nav-link:hover {
  color: #f90;
}

.user-nav {
  display: flex;
  align-items: center;

  .nav-link {
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: color 0.3s ease;

    &:hover {
      color: #f90;
    }

    img {
      margin-right: 8px;
    }
  }
}


