
.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.p_modal_boday {
  background: #ffffff;
  width: -moz-fit-content;
  width: fit-content;
  padding: 20px 20px 20px 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 5px #656565;
  position: absolute;
  top: 0;
  bottom: 0;
  height: fit-content;
  left: 0;
  right: 0;
  margin: auto;
}
.p_modal_parent{
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    background: #00000026;
    z-index: 99999;
    overflow: auto;   
}
.leaflet-bottom, .leaflet-top{
  display: none !important;
}
.layer-item-wrap{
  display: none !important;
}
.float-btn{
  width: 50px;
  height: 50px;
  background: rgb(0, 0, 0);
  position: fixed;
  bottom: 15px;
  border-radius: 50%;
  right: 15px;
  font-size: 20px;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
.float-btn>p{
  line-height: 2.3em;
}
/* .expand-map-control{
  display: none !important;
} */
.grid-container {
  display: grid;
  grid-template-columns: auto auto;  
}
.grid-item {  
  text-align: center;
}
.fixed-div{
  position: fixed;
  right: 0;
  top : 70px;  
}
.fixed-icon{ 
  font-size: 20px;  
  cursor: pointer;
  color: #888888;
}
.fixed-text{
  line-height: 2.4em;
  margin-bottom: 0px;
}
.fixed-toggle{
  display: none;
  border-radius: 0px 0px 10px 10px;
  background: #636363;
  border: 1px solid #565656;      
  padding: 0px 15px;    
  color: #fff;
}
.icon-div{
  position: absolute;
  top: 0;
  right: 100px;
}
.submit_rating{
  text-align: center;
  color: rgb(240, 118, 43);
  text-decoration: underline;
  margin-top: 15px;
  cursor: pointer;
}
.dt-bootstrap4 table th{  
  background-color: #f2fcff;
  text-align: center;
  border : none !important;
  padding: 15px !important;
  font-family: "Barlow Semi Condensed";
  font-weight: 800;
 font-size: 16px !important;  
  
}
.dt-bootstrap4 table td{  
  border : none !important;
  font-family: "Barlow Semi Condensed";
  font-weight: 600;
  font-size: 15px !important;
}
.dt-bootstrap4 table{
  margin-bottom: 0px !important;
}
.pagination .page-item .page-link{
  font-size: 1.3rem !important;
}
.pagination{
  float: right;
}
.table-bordered{
  border : none;
}
.dataTables_scroll{
  box-shadow: 0px 0px 15px 0px #e0e0e0;
  margin-top: 25px;
}
.datatable-search{
  margin-bottom: 25px !important;
}
/* added by mohan 21-01-12  hidding drop down last item in journey report*/
.cls_mddatatable > .row:nth-child(1) > div {
  float:right;
  background-color: blueviolet;
  width: 0;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 5%;
  width: 100%;
  background-color: black;
  color: white;
  text-align: center;
  font-size: 1rem !important;
}
.cls_version{
  float: right;
  font-size: 1rem !important;
}
.app_version{
  float: right;
  font-size: 1rem !important;
}
.privacy{
  float: left;
  font-size: 1rem !important;
}
.terms{
  float: center;
  font-size: 1rem !important;  
}
.p_modal_boday1 {
  background: #ffffff;
  width: -moz-fit-content;
  width: fit-content;
  padding: 20px 20px 20px 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 5px #656565;
  position: absolute;
  top: 0;
  bottom: 0;
  height: fit-content;
  left: 0;
  right: 0;
  margin: auto;
}
.p_modal_parent1{
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    background: #00000026;
    z-index: 99999;
    overflow: auto;   
}
.p_modal_boday2 {
  background: #ffffff;
  /* width: -moz-fit-content; */
  width: fit-content;
  padding: 20px 20px 20px 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 5px #656565;
  position: absolute;
  top: 0;
  bottom: 0;
  height: fit-content;
  left: 0;
  right: 0;
  margin: auto;
}

