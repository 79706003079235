@import "../common_style";

.vehicle_list_view {
  display: table;
  border-collapse: separate;
  margin-bottom: 15px;
  border-spacing: 10px 0px;
}
.vehicle_list_view > div {
  display: table-cell;
  cursor: pointer;
}
.selected_border {
  border-bottom: 2px solid #f0762b;
}
.unselected_border {
  border-bottom: none;
}
.map_view_div {
  height: 100%;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 2px #f3f3f3;
  border: 1px solid #f3f3f3;
}
.create_new_vehicle > div {
  margin-bottom: 40px;
}
.prev_next_div {
  display: table;
  border-collapse: separate;
  border-spacing: 10px 0px;
}
.prev_next_div > div {
  display: table-cell;
}
.previous_btn {
  background-color: #cccccc;
  cursor: pointer;
  color: #ffffff;
  text-transform: uppercase;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
  outline: 0;
  @include BSC_extra_bold18;
  font-size: 14px;
}
.previous_btn:focus,
.previous_btn:hover {
  background-color: #cccccc;
  color: #ffffff;
  outline: 0;
}
.vehile_uses > div {
  display: inline-block;
  padding: 10px;
  margin-right: 10px;
  border-radius: 10px;
  cursor: pointer;
}
.selected_btn {
  border: 1px solid #f0762b;
  color: #f0762b;
}
.unseleted_btn {
  border: 1px solid #cccccc;
  color: #cccccc;
}
#enter_vehicle_details > div,
#enter_vehicle_document > div {
  margin-bottom: 40px;
}
.vd_input {
  border: none;
  background-color: #eeeeee;
  padding: 15px;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 40px;
  outline: 0;
  @include BSC_semi_bold16;
}
.file_upload {
  display: none !important;
}
.upload_btn {
  float: none;
  margin-top: 20px;
}
.upload_btn > i {
  cursor: pointer;
}
.inline-block {
  display: inline-block;
}
.car-icon {
  display: block;
  margin: auto;
}
.vehicle_parent {
  @extend .map_view_div;
  position: relative;
}
.vd_icon {
  display: block;
  margin: auto;
}
.margin_bot_20 {
  margin-bottom: 10px;
}
.vl_assign {
  float: none !important;
  display: block;
  margin: 33px auto;
}
.vd_icon_1 {
  margin-top: 10px;
}
.pt_pb {
  padding-top: 30px;
  padding-bottom: 30px;
}
.vd_open_close {
  position: absolute;
  left: 50%;
  bottom: -13px;
}
.vehicle_stats {
  @extend .map_view_div;
}
.stats_data {
  border-right: 1px solid #cccccc;
  @include work_sans_regular16;
}
.vehicle_detail_parent > div {
  margin-bottom: 40px;
}
.vehicle_detail_parent > div > h3 {
  margin-bottom: 40px;
  @include BS_semi_bold;
}
.vd_journey_icon {
  float: right;
  color: #f0762b;
}
.vd_show_date {
  background-color: #eeeeee;
  padding: 10px;
  border-radius: 10px;
}
.vd_journeys > div:first-child {
  margin-bottom: 10px;
}
.vd_journeys {
  @extend .map_view_div;
  padding: 0px;
}
.orange-border {
  border: 1px solid #f0762b;
  color: #f0762b;
  background-color: #ffffff;
  text-transform: uppercase;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
  font-size: 15px !important;
}
.orange-border:hover,
.orange-border:focus {
  border: 1px solid #f0762b;
  color: #f0762b;
  background-color: #ffffff;
  text-transform: uppercase;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
  font-size: 15px !important;
}
.boundary_map_view {
  border-radius: 10px;
  height: 20em;
  background-color: #cccccc;
}
.vd_boundary_address {
  @extend .vd_show_date;
  width: 80% !important;
}
.bs_regular {
  @include BSC_regular;
}
.padding20 {
  padding: 20px 20px 20px 0px;
}
.enter_speed {
  @extend .vd_input;
  width: 80%;
  margin-bottom: 0px;
  @include BSC_semi_bold16;
}
.enter_region_speed {
  @extend .vd_input;
  width: 50%;
  margin-bottom: 0px;
  @include BSC_semi_bold16;
}
.edit_boundary_p {
  @include BSC_semi_bold16;
}
.show_file_name {
  border: 1px solid #f0762b;
  background-color: #f3f3f3;
  padding: 10px;
  border-radius: 10px;
  color: #f0762b;
  display: none;
}
.remove_file {
  background-image: linear-gradient(#f37425, #fe450c);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  position: absolute;
  top: -6px;
  right: -6px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
.show_file_name {
  position: relative;
}
.fa_action {
  color: #f0762b;
  cursor: pointer;
}
.boundar_tag {
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  padding-right: 20px;
  padding-left: 20px;
}
.boundar_tag1 {
  border-left: 1px solid #cccccc;
  padding-right: 20px;
  padding-left: 20px;
}
.suggesstion_dropdown {
  position: absolute;
  z-index: 999;
  background-color: #ffffff;
}
.grey-text {
  font-size: 25px !important;
}
.partition {
  border-right: 1px solid #cccccc;
}
.grid-container-vehicle {
  display: grid;
  grid-template-columns: auto auto auto;
}
.grid-item-vehicle {
  text-align: center;
  cursor: pointer;
  padding: 5px;
}
.selected-vehicle {
  text-align: right;
  color: #909090;
  margin: 0px;
  font-size: 20px;
  font-weight: 700;
}
.share_live_replay {
  position: absolute;
  top: 100px;
  z-index: 999;
  right: 57px;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #cccccc;
  box-shadow: 0px 0px 5px 1px #9a9a9a;
}
.select-tracking {
  font-weight: 700;
  border-bottom: 2px solid #f0762b;
}
.vehile_search_list {
  position: absolute;
  z-index: 999;
  background: #ffffff;
  width: 95%;
  padding: 10px;
  display: none;
}
.panning_button {
  position: absolute;
  top: 85px;
  z-index: 999;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 2px #cccccc;
  right: 30px;
  cursor: pointer;
}
.panning_button > p {
  margin-bottom: 0px;
}

// @media (max-width: 768px) {
//   .table-responsive {
//     display: block;
//     width: 100%;
//     overflow-x: auto;
//     -webkit-overflow-scrolling: touch;
//   }

//   .table-responsive > .table {
//     margin-bottom: 0;
//   }

//   .vehicle_detail_parent {
//     padding: 10px;
//   }

//   .vehicle_parent {
//     padding: 10px;
//   }

//   .vd_journeys {
//     padding: 10px;
//   }

//   .vehicle_stats {
//     padding: 10px;
//   }

//   .vehicle_list_view {
//     display: block;
//   }

//   .vehicle_list_view > div {
//     display: block;
//     width: 100%;
//     margin-bottom: 10px;
//   }

//   .grid-container-vehicle {
//     grid-template-columns: auto;
//   }

//   .grid-item-vehicle {
//     width: 100%;
//     margin-bottom: 10px;
//   }

//   .previous_btn,
//   .orange-border,
//   .btn {
//     width: 100%;
//     padding: 10px;
//     font-size: 12px;
//     margin-bottom: 10px;
//   }

//   .share_live_replay {
//     top: auto;
//     bottom: 10px;
//     right: 10px;
//   }

//   .panning_button {
//     top: auto;
//     bottom: 10px;
//     right: 10px;
//   }

//   .map_view_div {
//     padding: 10px;
//   }

//   .stats_data {
//     font-size: 14px;
//   }

//   .car-icon,
//   .vd_icon,
//   .vd_icon_1 {
//     width: 30px;
//     height: 30px;
//   }

//   .padding20 {
//     padding: 10px 10px 10px 0;
//   }

//   .vd_input,
//   .enter_speed,
//   .enter_region_speed {
//     padding: 10px;
//     font-size: 14px;
//   }

//   .boundary_map_view {
//     height: 15em;
//   }
// }




@media (max-width: 720px) {
    .vehicle_detail_parent {
        padding: 10px;
    }
    .vehicle_parent {
        flex-direction: column;
    }
    .inline-block {
        display: block;
        text-align: center;
    }
    .page_header img {
        width: 30px;
    }
    .journey_input {
        width: 100%;
    }
    .calendar_parent {
        width: 100%;
    }
    .tableParent {
        overflow-x: auto;
    }
    // .btn {
    //     width: 100%;
    // }
    .stats_data p {
        text-align: center;
    }
}


