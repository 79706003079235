@import "../../Component/color_pallet";
@import "../common_style";

.journey_over_parent{
    // background-image: url("/images/white_map.jpg");    
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;
    background-color: #f9f9f9;
}
.start_journey_loc{
    @include BS_semi_bold;
    text-transform:uppercase;
}
.end_journey_loc{
    @extend .start_journey_loc;
    color: $orange;
}
.small_button{
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-image: linear-gradient(to bottom, #ed7d28, #ff3501);
    text-align: center;
    box-shadow: 0 0 10px 0 rgba(244, 129, 31, 0.6);
    cursor: pointer;
}
.journey_detail_parent{
    padding-left: 100px;
    padding-right: 100px;
}
.journey_overview_tab{
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
    font-weight: 500;    
}
.journey_document_tab{
    display: inline-block;
    cursor: pointer;
}
.journey_overview_tab>p,
.journey_document_tab>p{
    margin-bottom: 0px;
    @include BS_semi_bold;
    font-size: 16px;
}

.CardView{
  //  height: 100%;
    height: 653px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 2px #d0d0d0;
    border: 1px solid #f3f3f3;
}

.detail_journey_div{
    @extend .CardView;
    margin-top: 20px;
    position: relative;
}

.p_header{    
    text-transform: uppercase;    
    @include BSC_extra_bold18
}
.p_big_grey{
    @include BSC_medium;
    color: #828282;
}
.p_margin_bottom{
    margin-bottom: 0px;
}
.p_date_time{
    @include BSC_semi_bold16;
}
.cust_hr{
    margin-top: 5px;
    margin-left: -20px;
    margin-right: -20px;
}
.journey_list_item{
    @extend .CardView;    
    margin-bottom: 30px;
}
.journey_list_parent{
    @extend .journey_detail_parent;
    background-color: #eeeeee;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 50px;
    margin-left: -100px;
    margin-right: -100px;
}
.journey_item_vehicle{
    @include BSC_extra_bold;
    text-transform: uppercase;
    margin-bottom: 0px;
}
.BSC_semi_bold18{
    @include BSC_semi_bold16;
    font-size: 18px;
}
.journey_alert_count{
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: #ff0000;
    text-align: center;    
    cursor: pointer;
    color: #ffffff;
    float: right;
}
.journey_alert_count>label{
    vertical-align: text-top;
    cursor: pointer;
}
.journey_icon{
    display: inline-block;
    position: relative;
    top: -34px;
}
.padding_left_20{
    padding-left: 25px;
}
.border_left{
    border-left: 1px solid #eeeeee;
}
.journey_driver_details{
    background-color: #eeeeee;    
    border-radius: 30px;
    margin-top: 20px;
}
.journey_driver_score{
    text-align: center;
    background-color: #f2fcff;
}
.journey_driver_score>p>span{
    font-size: 22px;
    margin-left: 10px;
}
.journey_driver_image{
    height: 30px;
    width: 30px;
}
.journey_driver_image>img{
    height: 100%;
    width: 100%;
}
.del_info_icon{
    vertical-align: bottom;
    color: #ffffff;
}
.border-orange{
    border-bottom: 2px solid #F0762B;
}
// .stop_address{
//     display: table-cell;
//     display: -webkit-box;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     height: 4em;
//     padding-left:20px;
//     padding-right: 10px;
// }
.stop_address>img{
    display: inline;
    margin-right: 10px;
}
.stop_address>p{
    display: inline;
    @include work_sans_regular16;
}
.stop_dur{
    @include BC_medium20;
    font-size: 16px;
    font-weight: 600;
}
.pd_user{
    @include BC_medium20;
    font-size: 16px;
    font-weight: 600;
}
.driver_mo_no{
    @include BSC_semi_bold18;
}
.bigger_p{
    font-size: 40px !important;
    @include BSC_extra_bold;
    color: #e2e2e2;
}
.stop_vehicle_div{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .navgation_image{
    display: block;
    margin: auto;
  }
  .navigation{
    height: 100%;
    width: 0px;
    border-right: 2px dashed #828282;
    margin: auto;
  }
  .stop-nav{
    height: 100%;
    width: 0px;
    border-right: 2px dashed #828282;
    margin: auto;
  }
  .stop-img{
      width: 85%;
  }
  .stop_margin_bottom{
    margin-bottom: 15px;
  }
  .journey-alert-icon{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    // padding: 11px;
    background: #ffffff;
    margin: auto;
  }
  .jo_noti_header {
      background-color: #eaf4f7;
      padding: 20px;
      border-radius: 10px 10px 0px 0px;
  }
  .jo_notification{
      padding: 30px 10px;
      background-color: #f7f7f7;
      border-radius: 0px 0px 10px 10px;
      max-height: 14em;
      overflow-y: auto;
      overflow-x: hidden;
  }
  .noti_date_time{
    font-size: 12px !important;
    color: #7b7b7b;
    font-weight: normal;
    font-family: "Work Sans";
    font-weight: 400;
    font-size: 14px;
  }
  .jo_noti_container{
      width: 100%;
      margin-bottom: 40px;
      position: relative;
      display: none;
  }
  .single_line_address { 
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.5em;    
    padding-right: 20px;
  }
  .jo_vd_open_close{
    
  }
  .jo_small_button{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #F0762B;
    text-align: center;
    box-shadow: 0px 0px 9px 2px #ffc19c;
    cursor: pointer;
    margin-top: 8px;
  }
  .jo_small_button>i{
      vertical-align: inherit;
  }
  .Triangle {
    width: 0; 
    height: 0; 
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;  
    border-left: 5px solid #000000;    
    display: inline-block;
    margin: 0px 10px 3px 10px;
  }
  .map_container{
    height: 400px;
    margin-bottom: 2em;
    position: relative;
  }
  .map_container>div{
      border-radius: 15px;
  }
  .rr_slider>div>div{
      display: inline-block;
      margin: 0px 10px;
  }
  .rr_slider>div{
    text-align: center;
    margin-top: 15px;
  }
  .replay_route_slider{
      background-color: #d9d9d9;
      border-radius: 0px 0px 15px 15px !important;
      color:#0a0707;
      position: absolute;
      width: 100%;    
      bottom: 0px;
      z-index: 999;      
  }
  .rr_value{
    font-size: 30px;
    font-weight: bold;
  }
  .slider_div{
      width: 80%;
  }
  .live_track_div{
      position: relative;
      margin-top: 15px;
  }
  .slider_div>span{
      padding: 0px !important;
  }
  .MuiSlider-rail{
      color: #979797;
  }
  .MuiSlider-root{
    color: #F0762B !important;
  }
  .MuiSlider-thumb	{
    color: #a7a7a7;
  }
  .map_zoom_icon{
    position: absolute;
    top: 10px;
    z-index: 9999;
    right: 10px;
    height: 40px;
    cursor: pointer;
  }
  .map_close_icon{
    position: absolute;
    top: -17px;
    z-index: 9999;
    right: -13px;
    height: 40px;
    cursor: pointer;
    display: none;
  }
  .zoom_in_map{
    position: fixed;
    width: 96%;
    height: 96%;
    top: 2%;
    left: 2%;
    z-index: 99999;
  }
  .v_start_journey{          
    background: -webkit-linear-gradient(#ed7d28, #ff3501);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;    
  }
  .v_stop_journey{      
    background: -webkit-linear-gradient(#dc3545, #a8101e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
  }
  @mixin table_header_text{
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .table_moving{
      background: -webkit-linear-gradient(#3e9ff9, #1e4b8e);
      @include table_header_text();
  }
  .table_idling{
      background: -webkit-linear-gradient(#ef165d, #9c208c);
      @include table_header_text();
  }
  .table_parked{
      background: -webkit-linear-gradient(#ffc600, #ff7e00);
      @include table_header_text();
}




@media only screen and (max-width: 360px) {
    .replay_route_slider {
      width: 100%;
      margin-bottom: -139px;
      left: 0;
    }
  
    .rr_slider {
      border-right: none;
      width: 100%;
      text-align: center;
      padding: 10px 0;
    }
  
    .slider_div {
      margin: 0 10px;
    }
  
    .rr_slider img {
      margin: 0 5px;
    }
  
    .rr_value {
      font-size: 20px;
    }
  
    .grid_container {
      flex-direction: column;
    }
  
    .grid_item {
      width: 100%;
      text-align: center;
      padding: 5px 0;
    }
  
    .grid_item p {
      margin: 5px 0;
    }
  }
  

  