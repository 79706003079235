.alert-icon {
    height: 100%;
    width: 100%;
    display: block;
    margin: auto;
}
.alert_icon_div {
    width: 65px;
    height: 65px;
    border-radius: 50%;    
    background: #ffffff;
    margin: auto;
    display: flex;
}
.alert_container {
    padding: 20px;
    border-bottom: 1px solid #e2e2e2;
}
.alert_container:nth-last-child(2),
.alert_container:last-of-type {
    border-bottom: none;
}
.alert_container:nth-child(odd) {
    border-right: 1px solid #e2e2e2;
}
.select_dropdown {
    width: 100%;
    padding: 11px;
    background: #e4e4e4;
    border: none;
    border-radius: 10px;
    outline: 0;
}

/* Media Queries for Mobile Devices */
@media (max-width: 720px) {
    .journey_alert_parent {
        padding: 10px;
    }
    .select_dropdown,
    .journey_input {
        width: 100%;
        margin-bottom: 10px;
    }
    .input_div {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .calendar_parent {
        width: 100%;
    }
    .btn-block {
        width: 100%;
        margin-bottom: 10px;
    }
    .document_expand_parent {
        width: 100%;
        overflow-x: auto;
    }
    .cj_w_header {
        font-size: 1rem;
        margin-bottom: 5px;
    }
    .alert_icon_div {
        width: 50px;
        height: 50px;
    }
    .alert_container {
        padding: 10px;
        border-bottom: 1px solid #e2e2e2;
    }
}
