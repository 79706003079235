.fs_parent {
  background-color: #ffffff;
  padding: 10px;
  margin-right: 100px;
  border-radius: 10px;
}
.fs_checkbox_parent {
  display: table;
  margin: auto;
}
.fs_checkbox_parent > div {
  padding-right: 10px;
}
.unselect_layout {
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  // background-color: #ffd1b4;
  border: 15px solid #ffd1b4;
  box-shadow: none;
  -webkit-transition: all 0.4s cubic-bezier(1, 0, 0, 1);
  -moz-transition: all 0.4s cubic-bezier(1, 0, 0, 1);
  -ms-transition: all 0.4s cubic-bezier(1, 0, 0, 1);
  -o-transition: all 0.4s cubic-bezier(1, 0, 0, 1);
  transition: all 0.4s cubic-bezier(1, 0, 0, 1);
}
.select_layout {
  padding: 25px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 0 30px 0 rgba(128, 129, 132, 0.2);
}
.select_layout > img,
.unselect_layout > img {
  width: 100%;
}
.isHidden {
  display: none; /* hide radio buttons */
}
.radio:checked + .select_layout {
  /* target next sibling (+) label */
  background-color: blue;
}

// layout widget css
.w_ongoing {
  background: #cccccc;
  padding: 3em;
}
.w_notification {
  background-color: #cccccc;
  height: 99%;
}
.w_content_div {
  background-color: #e4e4e4;
}
.h_content_div {
  background-color: #e4e4e4;
  height: 100%;
}
.vh_content_div {
  background-color: #e4e4e4;
  // height: 49.5%;
}
.widget_setting_container {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 2px #e4e4e4;
  height: 80%;
}
.widget_list {
//   height: 25%;
  width: 70%;  
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 2px #b1b1b1;
  position: relative;
  display: block;
  margin: 0px auto 20px auto;
}
.widget_list > img {
  width: 100%;
  height: 100%;
}
.widget_list_parent {
  background-color: #e4e4e4;
  margin-right: -100px;
  margin-top: -123px;
  margin-bottom: -15px;
  padding: 20px;
  overflow-x: auto;
}
.widget_setting {
  padding: 30px 100px 0px;
}
.fleet_highlight {
  color: #f0762b;
}
.w_content_div > div,
.h_content_div > div,
.vh_content_div > div {
  width: 100% !important;
  height: 100% !important;
}
.remove_icon {
  background-image: linear-gradient(#f37425, #fe450c);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  position: absolute;
  top: -6px;
  right: -6px;
  display: none;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
