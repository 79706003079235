#map{
   height: 79%;
    //height: 64%;
   // height: 72%;
    width: 100%; 
}
.stop_img_box{
    text-align: center;
    box-shadow: 0px 0px 5px 2px #cccccc;
    border-radius: 5px;
    margin: 5px;
    padding-bottom: 10px;
}
.stop_img_box>img{
    width: 100%;
    padding: 0px 5px;
    margin: 10px 0px;
    border-bottom: 1px solid #cccccc;
}
.stop_img_box>p{
    margin: 0px;
    font-weight: bold;
    font-size: 15px;
    text-transform: uppercase;
     padding: 5px 5px;
    border-bottom: 1px solid #ccc;
}