@import "../../Component/color_pallet";

.MuiExpansionPanel-root.Mui-expanded{
    margin: 0px !important;
}
.document_document_tab{
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
    font-weight: 500;
    border-bottom: 2px solid $orange;
}
.document_overview_tabs{
    display: inline-block;
    cursor: pointer;
}
.MuiPaper-elevation1{
    box-shadow: none !important;
}
.document_vehicle_details{
    padding: 0px 24px;
    margin: 12px 0px;
}
.document_expand_parent{
    margin-top: 40px;
    margin-bottom: 40px;
    border: 1px solid #e4e4e4;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 2px #ececec;;
}
.expand_panel{
    background-color: #f9f9f9;
    padding-bottom: 0px !important;
    max-height: 21em;
    overflow: auto;
}
.document_image{
    height: 200px;
    width: 150px;
}

.document_image_parent{
    background-color: #ffffff;
    padding: 15px;
    border-radius: 15px;
    margin-bottom: 10px;
    margin-right: 25px;
    box-shadow: 0px 0px 10px 2px #d0d0d0;
}
#journey_document>div{
    padding-left: 100px;
    padding-right: 100px;
}