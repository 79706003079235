@import '../common_style.scss';

.topnav {
    overflow: hidden;
    background-color: #f1f1f1;
  }
  
  .topnav a {
    float: left;
    display: block;
    color: black;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    border-bottom: 3px solid transparent;
  }
  
  .topnav a:hover {
    border-bottom: 3px solid red;
  }
  
  .topnav a.active {
    border-bottom: 3px solid red;
  }
  .cardShadow{
    // margin: 20px;
    box-shadow: 0px 0px 10px 10px #e4e4e4;
    border-radius: 15px;
    // margin-left: 100px;
   
    
  }
  .text{
    margin-left: 1px;
    margin-top: 23px;
    font-weight: bold;
    font-size: 12;
  }
  .summary_report_filters{
    background-color: #e4e4e4;
    padding: 10px;
    border-radius: 10px;
    height: 40px;
    margin-top: 5px;
    @include BSC_semi_bold16;


  }
  .pdf_excel_download{  
    text-align: right;
    color : #F0762B;
    font-size: 15px;
  }
  .pdf_excel_download>span{    
    cursor: pointer;
  }
  .report_filter_div{
    height: 100%;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 2px #f3f3f3;
    border: 1px solid #f3f3f3;
  }
  .summary_report_parent>div{
   margin-top: 10px;
   margin-bottom: 10px;
   @include work_sans_regular16;
  }
  .text_ellipse{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include BSC_semi_bold16;

  } 
  .top_vehicle_border:first-child{
    border-bottom: 1px solid #eeeeee;
  }
  .top_vehicle_border:nth-child(2){
    border-left: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    padding-left: 20px;
  }
  .top_vehicle_border:nth-child(4){
    border-left: 1px solid #eeeeee;    
    padding-left: 20px;
  }
  .vr_tableHeader{
    background-color: #ececec;
    text-transform: uppercase;
    font-weight: bold;
  }
  .vr_tableHeader>tr>th{
    padding: 15px !important;
    border-bottom: none !important;
    text-align: center;
  }
  .vr_tableHeader>tr>th:nth-child(1){
    border-right: 1px solid #d6d6d6;
  }
  .vr_tableBody>tr>td{
    border-top: none !important;
    border-bottom: 1px solid #d6d6d6;
    padding: 15px !important;
    text-align: center;
  }
  .vr_tableBody>tr>td:first-child{
    border-right: 1px solid #d6d6d6;
  }
  .vr_tableBody>tr:last-child>td{
    border-bottom: none !important;
  }
  .vr_table_view1{
    border-radius: 10px;
    box-shadow: 0px 0px 10px 2px #eaeaea;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .active_view_btn{
    padding: 12px 30px;
    background-image: linear-gradient(#f37425, #fe450c);
    color: #ffffff;
    border-radius: 13px;
  }
  .inactive_view_btn{
    padding: 12px 30px;
    color: #828282;
  }
  .graph_view_btn{    
    text-transform: uppercase;
    border-radius: 13px;
    box-shadow: 0px 0px 10px 2px #eaeaea;
    width: fit-content;
   
  }

  .graph_btn{    
    text-transform: uppercase;
    border-radius: 13px;
    border: #828282;
    //box-shadow: 0px 0px 10px 2px #eaeaea;
    width: fit-content;
    margin-right: 20px;
   
  }

 


  .graph_btn2{    
    text-transform: uppercase;
    border-radius: 13px;
    border: #828282;
    //box-shadow: 0px 0px 10px 2px #eaeaea;
    width: fit-content;
    margin-right: 20px;
   
  }
  .graph_view_btn>div>label{
    margin-bottom: 0px;
  }
  .vr_graph_view h3{
    margin: 0px;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 10px;
  }
  .vr_download{
    text-align: center;
    padding-top: 10px;
    color: #F0762B;
  }
  .vr_graph_view{
    border-radius: 10px;
    box-shadow: 0px 0px 10px 2px #eaeaea;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 20px;
  }
  

  .box_graph_view{
    border-radius: 20px;
    box-shadow: 0px 0px 10px 2px #eaeaea;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 20px;
  }
  .all_region_dd{
    position: absolute;
    display: none;    
  }

  .c_no-wrap{
    overflow-wrap: normal;
    white-space: nowrap;
  }
  .disable-drop{
    pointer-events: none;
    opacity: 0.4;
  }
  .react-pagination-table{
    overflow: auto;
  }
  .table-header>tr>th{
    cursor: pointer;
  }
  .disable-click{
    pointer-events: none;
    opacity: 0.4;
  }
  .time_div{
    padding: 10px;
    background-color: #e4e4e4;
    border-radius: 10px;
  }
  .time_div>p{
    margin: 0px;
    font-weight: 700;
  }
  .reports th {
    background-color: #f2fcff;
    text-align: center;
    border: none !important;
    padding: 15px !important;
    font-family: "Barlow Semi Condensed";
    font-weight: 800;
    font-size: 16px !important;
  }

  .reports td {
    border: none !important;
    font-family: "Barlow Semi Condensed";
    font-weight: 600;
    font-size: 15px !important;
  }

  .report_filter_div1{
    height: 100%;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 2px #f3f3f3;
    border: 1px solid #f3f3f3;
  }

  .report_filter_div2{
    height: 100%;
    background-color: #ffffff;
    padding: 20px;
    //border-radius: 10px;
    box-shadow: 0px 0px 10px 2px #f3f3f3;
    border: 1px solid #f3f3f3;
    border-left: 5px solid #FF5E00;
  }

  #searchbar{
    width: 100%;
    margin-bottom: 10px;
  }

  #searchbar123{
    width: 100%;
    margin-bottom: 10px;
  }

  #searchbar1{
    width: 100%;
    margin-bottom: 10px;
  }



  /* reports.scss */
.maindiv {
  padding: 20px; /* Add padding to the main div */
}

.main_dashbord, .box6, .box3 {
  padding: 16px; /* Set padding */
  text-align: center; /* Center the text */
  color: #333; /* Set text color */
  border: 1px solid #ddd; /* Set border */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
  margin: 10px 0; /* Add vertical margin */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.main_dashbord {
  height: 150px; /* Set height for the main dashboard */
}

.box6 {
  height: 120px; /* Set height for larger boxes */
}

.box3 {
  height: 100px; /* Set height for smaller boxes */
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .main_dashbord, .box6, .box3 {
    width: 100%; /* Ensure full width on small screens */
  }
}

@media (min-width: 600px) {
  .main_dashbord {
    width: 100%;
  }
  .box6 {
    width: calc(50% - 20px); /* Adjust width for larger boxes with margin */
  }
  .box3 {
    width: calc(25% - 20px); /* Adjust width for smaller boxes with margin */
  }
  
}


// ************************************************************   IMD Dashbord ******************************************



.select_aom_box {
  padding: 20px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 20px;
  margin-top: 20px;
  box-shadow: 0px 0px 10px 2px #f3f3f3;
}

.underaom_div {
  padding: 20px;
  margin-top: 40px;
  background-color: #92919969;
  border-radius: 20px; 
  box-shadow: 0px 0px 10px 2px #7e7b7b;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-image 0.3s ease, background-color 0.3s ease;
}
.underaom_div:hover {
  box-shadow: 0px 0px 10px 2px #7e7b7b;
  background-color: #f0f0f0;
  transform: scale(1.02);
}

.select_aom_box1 {
  padding: 20px;
  margin: 20px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 2px #f3f3f3;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-image 0.3s ease, background-color 0.3s ease;
}

.select_aom_box1:hover {
  background-color: #fff;
  background-image: linear-gradient(to bottom, #ed7d28, #FFDB58);
  box-shadow: 0 0 10px 0 rgba(244, 129, 31, 0.6) !important;
  transform: scale(1.05);
  box-shadow: 0px 0px 10px 2px #f3f3f3;
}

.select_aom_box2 {
  margin: 20px;
}

.aom_container {
  margin-top: 20px;
}

.aom_header {
  margin: 20px;
}

.p_header {
  text-transform: uppercase;
  font-family: "Barlow Semi Condensed";
  font-weight: 800;
  font-size: 18px;
  cursor: pointer;
}

.turned_ON, .turned_oFF, .fse_dg_count1, .fse_count1 {
  font-weight: 600;
  font-size: 25px;
  color: black;
}

.fse_name {
  padding: 20px;
  margin-top: 20px;
  color: #333;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 10px 2px #f3f3f3;
  transition: height 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
}

.fse_name.open {
  height: auto;
  opacity: 1;
}

.p_header1 {
  text-transform: uppercase;
  margin-left: 20px;
  font-family: "Barlow Semi Condensed";
  font-weight: 800;
  font-size: 24px;
  cursor: pointer;
}

.fse_table, .third_table, .fourth_table, .summary-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.fse_table th, .fse_table td, .third_table th, .third_table td, .fourth_table th, .fourth_table td, .summary-table th, .summary-table td {
  padding: 10px;
  font-size: 16px;
  text-align: center;
  // border: 1px solid #dcdcdc;
  border: 1px solid #adacac;
}

.fse_table th, .third_table th, .summary-table th {
  font-weight: bold;
  font-size: 18px;
  background-color: #ddd;
}

.fourth_table th {
  font-weight: bold;
  font-size: 16px;
  background-color: #b2ffe8;
}

.fse_list, .aom_list {
  transition: background-color 0.3s ease;
  cursor: pointer;
  transition: height 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
}

.fse_list:hover, .aom_list:hover {
 // background-color: #b0f5ff63;
  background-color: #F7D5A6;
  border-radius: 10px;
}

.fse_dg_name_count, .fse_dg_count, .fse_start_count, .fse_stop_count {
  font-weight: 400;
  font-size: 16px;
  color: #333;
}

.grid_item {
  margin-top: 10px;
}

.fse_list.selected {
 
     background-color: #F7D5A6;
     border-radius: 10px;
      box-shadow: 0px 0px 10px 2px #e9dddd;
}


.aom_list.selected {
 
  background-color: #F7D5A6;
  border-radius: 10px;
   box-shadow: 0px 0px 10px 2px #e9dddd;
}


.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  /* Add spinner styles here */
}


.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top: 8px solid #2d21d3;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


#scroll{
  position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
  //  background: #00000026;
    z-index: 99999;
    overflow: auto;
}

#scrolling{
  background: #ffffff;
  width: -moz-fit-content;
  width: fit-content;
  padding: 20px 20px 20px 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 5px #656565;
  position: absolute;
  top: 0;
  bottom: 0;
  height: -moz-fit-content;
  height: fit-content;
  left: 0;
  right: 0;
  margin: auto;
  border-top: none !important;
  font-size: 16px;

}

.p_modal_boday {
  background: #ffffff;
  width: -moz-fit-content;
  width: fit-content;
  padding: 20px 20px 20px 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 5px #656565;
  position: absolute;
  border-top: none !important;
  top: 0;
  bottom: 0;
  height: fit-content;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 16px;

}
.multi-level-list {
  ul {
    list-style-type: none;
    padding-left: 0;
    cursor: pointer;
    font-size: 16px;

    margin: 0;

    li {
      margin: 5px 0;
      cursor: pointer;
      padding: 5px 0;
      color: #007bff; /* Link color */
      text-decoration: none;
      font-size: 16px;

      &:hover {
        text-decoration: underline;
      }

      ul {
        padding-left: 15px;
        margin-top: 5px;
        font-size: 16px;

      }
    }
  }
}


.table > thead:first-child > tr:first-child > th {
  border-top: 0;
  font-size: 14px;
}

.table > tbody > tr > td {
  border-top: none !important;
  text-align: center;
  font-size: 16px;
}


.summary-table, .cluster-table, .fse-table, .technician-table {
  width: 100%;
  font-size: 16px;
  border-collapse: collapse;
}





#scrollings {
  background: #ffffff;
  width: -moz-fit-content;
  width: fit-content;
  padding: 20px 20px 20px 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 5px #656565;
  position: absolute;
  top: 0;
  bottom: 0;
  height: -moz-fit-content;
  height: fit-content;
  left: 0px;
  right: 209px;
  margin: auto;
  border-top: none !important;
  font-size: 16px;
}