.react-pagination-table {
    position: relative;
    border-radius: 3px;
    background: #ffffff;
    margin-bottom: 20px;    
}

.react-pagination-table__sub-title {
    float: right;
}

.react-pagination-table__title,
.react-pagination-table__sub-title {
    display: inline-block;
    font-size: 18px;
    margin: 0;
    line-height: 1;
    font-weight:100;
    padding: 20px 20px 10px;
}

.react-pagination-table__header {
    background: #e8e8e8;
}

.react-pagination-table__table {
    text-align: center;
    width: 100%;
   // max-width: 100%;
    margin-bottom: 20px;
    border: 1px solid #ddd;
}

.pagination-status {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
}

.pagination-status__item {
    display: inline;
}

.pagination-status .pagination-status__btn {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    color: #337ab7;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
}

.pagination-status .pagination-status__btn:focus {
    outline: none;
}

.pagination-status .pagination-status__btn--active,
.pagination-status .pagination-status__btn:hover {
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7;
    cursor: pointer;
}

.pagination-status .pagination-status__btn--disable,
.pagination-status .pagination-status__btn--disable:hover {
    color: #d0d0d0;
    cursor: default;
    border-color: #d0d0d0;
    background-color: #fff;
}
.table-header{
    background-color: #f2fcff;
}
.table-header>tr>th{
    border-bottom: none !important;
    padding: 15px !important;
    text-align: center;
}
.table>tbody>tr:nth-child(even){
    background-color: #fbfbfb;
}
.table>tbody>tr>td{
    border-top: none !important;
    text-align: center;
    // width: 20% !important;
}
.table{
    box-shadow: 0px 3px 15px 6px #f3f3f3;
}


@import '../common_style.scss';

.topnav {
    overflow: hidden;
    background-color: #f1f1f1;
}
  
.topnav a {
    float: left;
    display: block;
    color: black;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    border-bottom: 3px solid transparent;
}
  
.topnav a:hover {
    border-bottom: 3px solid red;
}
  
.topnav a.active {
    border-bottom: 3px solid red;
}
.cardShadow {
    box-shadow: 0px 0px 10px 10px #e4e4e4;
    border-radius: 15px;
}
.text {
    margin-left: 1px;
    margin-top: 23px;
    font-weight: bold;
    font-size: 12px;
}
.summary_report_filters {
    background-color: #e4e4e4;
    padding: 10px;
    border-radius: 10px;
    height: 40px;
    margin-top: 5px;
    @include BSC_semi_bold16;
}
.pdf_excel_download {
    text-align: right;
    color: #F0762B;
    font-size: 15px;
}
.pdf_excel_download>span {
    cursor: pointer;
}
.report_filter_div {
    height: 100%;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 2px #f3f3f3;
    border: 1px solid #f3f3f3;
}
.summary_report_parent>div {
    margin-top: 10px;
    margin-bottom: 10px;
    @include work_sans_regular16;
}
.text_ellipse {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include BSC_semi_bold16;
}
.top_vehicle_border:first-child {
    border-bottom: 1px solid #eeeeee;
}
.top_vehicle_border:nth-child(2) {
    border-left: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    padding-left: 20px;
}
.top_vehicle_border:nth-child(4) {
    border-left: 1px solid #eeeeee;
    padding-left: 20px;
}
.vr_tableHeader {
    background-color: #ececec;
    text-transform: uppercase;
    font-weight: bold;
}
.vr_tableHeader>tr>th {
    padding: 15px !important;
    border-bottom: none !important;
    text-align: center;
}
.vr_tableHeader>tr>th:nth-child(1) {
    border-right: 1px solid #d6d6d6;
}
.vr_tableBody>tr>td {
    border-top: none !important;
    border-bottom: 1px solid #d6d6d6;
    padding: 15px !important;
    text-align: center;
}
.vr_tableBody>tr>td:first-child {
    border-right: 1px solid #d6d6d6;
}
.vr_tableBody>tr:last-child>td {
    border-bottom: none !important;
}
.vr_table_view1 {
    border-radius: 10px;
    box-shadow: 0px 0px 10px 2px #eaeaea;
    margin-top: 30px;
    margin-bottom: 30px;
}
.active_view_btn {
    padding: 12px 30px;
    background-image: linear-gradient(#f37425, #fe450c);
    color: #ffffff;
    border-radius: 13px;
}
.inactive_view_btn {
    padding: 12px 30px;
    color: #828282;
}
.graph_view_btn {
    text-transform: uppercase;
    border-radius: 13px;
    box-shadow: 0px 0px 10px 2px #eaeaea;
    width: fit-content;
}
.graph_btn {
    text-transform: uppercase;
    border-radius: 13px;
    border: #828282;
    width: fit-content;
    margin-right: 20px;
}
.graph_btn2 {
    text-transform: uppercase;
    border-radius: 13px;
    border: #828282;
    width: fit-content;
    margin-right: 20px;
}
.graph_view_btn>div>label {
    margin-bottom: 0px;
}
.vr_graph_view h3 {
    margin: 0px;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 10px;
}
.vr_download {
    text-align: center;
    padding-top: 10px;
    color: #F0762B;
}
.vr_graph_view {
    border-radius: 10px;
    box-shadow: 0px 0px 10px 2px #eaeaea;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 20px;
}
.box_graph_view {
    border-radius: 20px;
    box-shadow: 0px 0px 10px 2px #eaeaea;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 20px;
}
.all_region_dd {
    position: absolute;
    display: none;
}
.c_no-wrap {
    overflow-wrap: normal;
    white-space: nowrap;
}
.disable-drop {
    pointer-events: none;
    opacity: 0.4;
}
.react-pagination-table {
    overflow: auto;
}
.table-header>tr>th {
    cursor: pointer;
}
.disable-click {
    pointer-events: none;
    opacity: 0.4;
}

.time_div {
    padding: 10px;
    background-color: #e4e4e4;
    border-radius: 10px;
}
.time_div>p {
    margin: 0px;
    font-weight: 700;
}

  .endtime{
    margin-left: -201px;
  }

.reports th {
    background-color: #f2fcff;
    text-align: center;
    border: none !important;
    padding: 15px !important;
    font-family: "Barlow Semi Condensed";
    font-weight: 800;
    font-size: 16px !important;
}
.reports td {
    border: none !important;
    font-family: "Barlow Semi Condensed";
    font-weight: 600;
    font-size: 15px !important;
}
.report_filter_div1 {
    height: 100%;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 2px #f3f3f3;
    border: 1px solid #f3f3f3;
}
.report_filter_div2 {
    height: 100%;
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0px 0px 10px 2px #f3f3f3;
    border: 1px solid #f3f3f3;
    border-left: 5px solid #FF5E00;
}
#searchbar {
    width: 100%;
    margin-bottom: 10px;
}
#searchbar123 {
    width: 100%;
    margin-bottom: 10px;
}
#searchbar1 {
    width: 100%;
    margin-bottom: 10px;
}
.maindiv {
    padding: 20px; /* Add padding to the main div */
}
.main_dashbord, .box6, .box3 {
    padding: 16px; /* Set padding */
    text-align: center; /* Center the text */
    color: #333; /* Set text color */
    border: 1px solid #ddd; /* Set border */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
    margin: 10px 0; /* Add vertical margin */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}
.main_dashbord {
    height: 150px; /* Set height for the main dashboard */
}
.box6 {
    height: 120px; /* Set height for larger boxes */
}
.box3 {
    height: 100px; /* Set height for smaller boxes */
}
/* Responsive adjustments */
@media (max-width: 600px) {
    .main_dashbord, .box6, .box3 {
        width: 100%; /* Ensure full width on small screens */
    }
}
@media (min-width: 600px) {
    .main_dashbord {
        width: 100%;
    }
    .box6 {
        width: calc(50% - 20px); /* Adjust width for larger boxes with margin */
    }
    .box3 {
        width: calc(25% - 20px); /* Adjust width for smaller boxes with margin */
    }
}
@media (max-width: 360px) {
    .report_filter_div {
        padding: 10px;
        border-radius: 10px;
        box-shadow: 0px 0px 5px 1px #f3f3f3;
    }
    .time_div {
        padding: 5px;
    }
    .pdf_excel_download {
        font-size: 12px;
    }
}
