@import '../common_style.scss';
.journeryList{
    padding-left: 100px;
    padding-right: 100px;
    margin-bottom: 50px;
}
.tableHeading{
    font-size: 35px; 
    @include BS_semi_bold;   
}
.tableHeading>label{
    float: left;
}
.clear_all{
    color: #F0762B;    
    display: inline;
    cursor: pointer;
}
.uj_single_line_address{
    width: 20%;
    margin: auto;
}
.status-filter{
    position: absolute;
    background: transparent;
    padding: 8px 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
}
.pudo-filter{
    background: transparent;
    padding: 8px 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
}
.vehicle-filter{
    background: transparent;
    padding: 8px 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
}
.driver-filter{
    background: transparent;
    padding: 8px 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
}
