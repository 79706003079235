#btn:focus {
    outline: none !important;
    outline-offset: none !important;
}

#usr{
    border-radius: 8px;
    margin-left: 20%;
    background-color: #f0f0f0;
    border: none;
    margin-left: 16px;
    margin-top: 15px;
    width: 91%;
}
#vehicleDiv{
    margin-top: 3%;
    margin-left: 5%;
}

.Corn{
    border-radius: 2px;
}
.card{
    box-shadow: 0px 0px 10px 10px #e4e4e4;
    border-radius: 15px;
}