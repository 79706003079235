@import "../../common_style";

.setting_parent {
  padding-right: 100px;
  padding-left: 100px;
}
.setting_opt {
  display: table;
  margin-top: 20px;
  border-collapse: separate;
  border-spacing: 20px 0;
}
.setting_opt > div {
  display: table-cell;
  color: #808184;
  @include BS_semi_bold;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}
.settting_sel {
  border-bottom: 2px solid #f4811f;
  color: #000000 !important;
}
.profile_image {
  height: 81px;
  width: 81px;
  border-radius: 50%;
  display: block;
  margin: auto;
  margin-top: 10px;
}
@mixin shadow {
  border-radius: 20px;
  box-shadow: 0 0 30px 0 rgba(128, 129, 132, 0.2);
}
.user_profile {
  @include shadow;
  padding: 20px;
}
.sub_data {
  text-align: right;
}
.sub_data > p {
  @include BSC_semi_bold16;
}
.vehicle_sub {
  @include shadow;
  padding: 20px;
}
.profile_parent > div {
  margin-bottom: 45px;
}
.sub_btn {
  float: none !important;
  display: block;
  margin: auto;
  margin-top: 15px;
}
.edit_p_head {
  @include BS_semi_bold;
  font-size: 16px;
  color: #000000;
}
.p_img_name {
  display: flex;
  margin-bottom: 30px;
}
.p_img_name > div {
  display: table-cell;
}
.p_img_name > div:nth-child(2) {
  margin-top: 22px;
  margin-left: 22px;
}

.p_img_name > div > img {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  display: block;
  margin: auto;
}
.renew_header {
  border-bottom: 1px solid #e2e2e2;
  border-top: 1px solid #e2e2e2;
  padding-top: 15px;
  padding-bottom: 5px;
  margin-bottom: 25px;
}
.renew_price_year {
  @include BSC_medium;
  color: #333333;
}
.renew_modal_body {
  padding: 60px;
}
.renew_modal_header {
  @include BSC_medium;
  color: #666666;
}
.renew_modal_header_car {
  @include BSC_semi_bold16;
  font-size: 36px;
  color: #000000;
}
.s_center_image {
  margin: auto;
  display: block;
}
.alert_report_h {
  @include BSC_medium;
  font-size: 16px;
}
.email_r_header {
  @include work_sans_SB16;
}
.email_right_text {
  text-align: left;
}
.region_map_div {
  height: 400px;
  margin-top: 20px;
  margin-bottom: 40px;
}

// dashboard setting css
.fs_parent {
  background-color: #ffffff;
  padding: 10px;
  margin-right: 100px;
  border-radius: 10px;
}
.s_fs_checkbox_parent {
  display: table;
  margin: auto;
}
.s_fs_checkbox_parent > div {
  padding-right: 10px;
}
.s_unselect_layout {
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  // background-color: #ffd1b4;
  border: 15px solid #ffd1b4;
  box-shadow: none;
  -webkit-transition: all 0.4s cubic-bezier(1, 0, 0, 1);
  -moz-transition: all 0.4s cubic-bezier(1, 0, 0, 1);
  -ms-transition: all 0.4s cubic-bezier(1, 0, 0, 1);
  -o-transition: all 0.4s cubic-bezier(1, 0, 0, 1);
  transition: all 0.4s cubic-bezier(1, 0, 0, 1);
}
.s_select_layout {
  padding: 25px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 0 30px 0 rgba(128, 129, 132, 0.2);
}
.s_select_layout > img,
.s_unselect_layout > img {
  width: 100%;
}
.s_isHidden {
  display: none; /* hide radio buttons */
}
.radio:checked + .select_layout {
  /* target next sibling (+) label */
  background-color: blue;
}

// layout widget css
.s_w_ongoing {
  background: #cccccc;
  padding: 3em;
}
.s_w_notification {
  background-color: #cccccc;
  height: 100%;
}
.s_w_content_div {
  background-color: #e4e4e4;
}
.s_h_content_div {
  background-color: #e4e4e4;
  height: 100%;
}
.s_vh_content_div {
  background-color: #e4e4e4;
  // height: 49.5%;
}
.s_widget_setting_container {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 2px #e4e4e4;
  height: 80%;
}
.add_widget {
  height: calc(100% - 95px);
}
.add_widget > div {
  height: 100%;
}
.s_widget_list {
  // height: 25%;
  width: 80%;
  // padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 2px #b1b1b1;
  position: relative;
  display: block;
  margin: 0px auto 20px auto;
}
.s_widget_list > img {
  width: 100%;
  height: 100%;
}
.s_widget_list_parent {
  background-color: #e4e4e4;
  margin-top: -121px;
  margin-bottom: -15px;
  padding: 20px;
  overflow-x: auto;
}
.s_widget_setting {
  padding: 30px 100px 0px;
}
.s_fleet_highlight {
  color: #f0762b;
}
.s_w_content_div > div,
.s_h_content_div > div,
.s_vh_content_div > div {
  width: 100% !important;
  height: 100% !important;
}
.s_remove_icon {
  background-image: linear-gradient(#f37425, #fe450c);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  position: absolute;
  top: -6px;
  right: -6px;
  display: none;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
.s_layout_btn {
  position: fixed;
  bottom: 0;
}
.s_layout_btn > button {
  margin-right: 20px;
  padding-right: 20px;
  padding-left: 20px;
  float: none;
}
.s_back_btn {
  transform: rotate(-180deg);
  cursor: pointer;
}
.MuiSlider-valueLabel {
  font-size: 15px !important;
  color: #000000 !important;
  font-weight: bold !important;
}
.save_region_alerts {
  width: 50px;
  float: right;
  margin-top: -15px;
}
.api_image{
  width: 100%;
  display: block;
  margin: auto;
}
.clickable-text{
  color: #F0762B;
  text-decoration: underline;
  font-weight: 700;
  cursor: pointer;
}
.api_vehicle_list>thead>tr>td,
.api_vehicle_list>tbody>tr>td{
  border : 1px solid #000000;
  padding: 10px;
}
.href_style,
.href_style:hover,
.href_style:focus{
  color: #F0762B;
}
#api_key{
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
}
@mixin legends_size{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;  
  cursor: pointer;
}
.can_use{
  @include legends_size;
  background-color: #90ca57;
}
.recommded{
  @include legends_size;
  background-color: #f1d628;
}
.age{
  float: right;
  font-size: 10px;
  text-align: center;
  line-height: 2em;
  font-weight: 700;
  color: #ffffff;
  width: 20px;
  height: 20px;
}
.help_text{
  font-size: 12px;
  color: rgb(220, 53, 69);
  margin-bottom: 0px;
  margin-top: 5px;
}
.select_region{
  border : 3px solid #F0762B;
}
.disabled-div{
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 1px solid #cccccc;
  text-align: center;  
  line-height: 25px;
  float: right;
  background-color: #e8e8e8;
  font-weight: 600;
}

@mixin  p_legend {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.fuel_legend{
  @include p_legend;
  background-color: #fe450c;
}

.ei_legend{
  @include p_legend;
  background-color: #90ca57;
}

.fuel_present{
  background-color: #fe450c;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 1px solid #cccccc;
  text-align: center;  
  line-height: 25px;
  float: right;  
  font-weight: 600;
  color: #ffffff;
}

.ei_present{
  background-color: #90ca57;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 1px solid #cccccc;
  text-align: center;  
  line-height: 25px;
  float: right;  
  font-weight: 600;
  color: #ffffff;
}
.content_div{
  padding: 10px;
  text-align: center;
  border: 1px solid #cccccc;
}
.content_div>p{
  margin: 0px;
}
.search-drop{
  border: 1px solid #cccccc;
  position: absolute;
  background: #ffffff;
  width: 90%;
  padding: 10px 10px 0px 10px;
  display: none;
}
.vendor_vehicle {
  border: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
}
.untrack_list {
  position: absolute;
  width: 90%;
  background: #ffffff;
  border: 1px solid #ccc;
  padding: 10px;
  max-height: 150px;
  overflow: auto;
  display: none;
}
.untrack_list>p{
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  padding: 5px;
}


// .search-drop>p{
//   border-bottom: 1px solid #cccccc;
// }
