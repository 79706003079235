$orange: #f0762b;

@mixin BS_semi_bold {
  font-family: "Barlow Condensed";
  font-weight: 600;
  font-size: 36px;
}
@mixin BC_medium20 {
  font-family: "Barlow Condensed";
  font-size: 20px;
  font-weight: 500;
}
@mixin BSC_regular {
  font-family: "Barlow Condensed";
  font-size: 16px;
  font-weight: normal;
}
@mixin BSC_extra_bold18 {
  font-family: "Barlow Semi Condensed";
  font-weight: 800;
  font-size: 18px;
}
@mixin BSC_medium {
  font-family: "Barlow Semi Condensed";
  font-weight: 500;
  font-size: 36px;
}
@mixin BSC_extra_bold {
  font-family: "Barlow Semi Condensed";
  font-weight: 800;
  font-size: 30px;
}
@mixin work_sans_regular16 {
  font-family: "Work Sans";
  font-weight: 400;
  font-size: 16px;
}
@mixin work_sans_regular14 {
  font-family: "Work Sans";
  font-weight: 400;
  font-size: 14px;
}
@mixin work_sans_SB16 {
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 600;
}
@mixin work_sans_bold30 {
  font-family: "Work Sans";
  font-weight: 800;
  font-size: 30px;
}
@mixin BSC_semi_bold18 {
  font-family: "Barlow Semi Condensed";
  font-weight: 600;
  font-size: 18px;
}
@mixin BSC_semi_bold16 {
  font-family: "Barlow Semi Condensed";
  font-weight: 600;
  font-size: 16px;
}

@mixin cardView {
  height: 100%;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 2px #f3f3f3;
  border: 1px solid #f3f3f3;
}

@mixin orange-btn {
  background-image: linear-gradient(to bottom, #ed7d28, #ff3501);
  box-shadow: 0 0 10px 0 rgba(244, 129, 31, 0.6) !important;
  cursor: pointer;
  color: #ffffff !important;
  @include BSC_extra_bold;
  text-transform: uppercase;
  border-radius: 10px !important;
  font-size: 14px !important;
  padding: 10px;
  float: right;
  margin-top: 10px;
  outline: 0;
}
.orange-btn:hover,
.orange-btn:focus {
  color: #ffffff !important;
  background-image: linear-gradient(to bottom, #ed7d28, #ff3501);
  outline: none;
}
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
  background: transparent;
}

::-webkit-scrollbar-track {
  border-radius: 15px;
}

::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background: #e8e8e8;
}
.react-tabs__tab-list {
  border-bottom: none !important;
}


.react-tabs__tab{
  font-size: 12px;
}

.react-tabs__tab--selected {
  /* background: #fff; */
  font-size: 16px;
  border-color: transparent !important;
  color: black;
  /* border-radius: 5px 5px 0 0; */
  border-bottom: 2px solid $orange !important;
}
.tableOuterDiv {  
}
.tableParent {
  padding: 0px !important;
}
.tableParent > div > table {
  margin-bottom: 0px !important;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}
.tableHeader {
  background-color: #f2fcff;
  padding: 10px;
}
.tableHeader > tr{
  display: block;  
}
.tableHeader > tr > th {
  text-align: center;
  padding: 15px !important;
  border-bottom: none !important;
  text-transform: uppercase;
  @include BSC_extra_bold18;  
}
.tableBody{
  display: block;
  overflow-y: auto;
  height: 300px;
  width: 100%;
}
.tableBody > tr > td {
  padding: 15px !important;
  border-top: none !important;
  cursor: pointer;
  text-align: center;
  @include BSC_semi_bold16;
}
.tableBody > tr:nth-child(even) {
  background: #fcfcfc;
}

.modal_parent {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  background: #00000075;
  z-index: 99999;
  display: none;
  overflow: auto;
}

.modal_body {
  background: #ffffff;
  width: 40%;
  margin: auto;
  margin-top: 8%;
  margin-bottom: 8%;
  padding: 20px 20px 20px 40px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 5px #656565;
  position: relative;
}
.modal_header {
  @include BSC_extra_bold;
  font-size: 36px;
  color: #cccccc;
}
.close_modal {
  background-image: linear-gradient(#f37425, #fe450c);
  height: 22px;
  width: 22px;
  border-radius: 50%;
  position: absolute;
  top: -6px;
  right: -6px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
.custom_select {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #e4e4e4;
  border-radius: 10px;
  outline: 0;
}
.custom_select > option {
  background-color: #ffffff;
}
.calendar_parent {
  position: absolute;
  width: 16%;
  background-color: #ffffff;
  z-index: 999;
  box-shadow: 0px 0px 10px 2px #f3f3f3;
  padding: 10px;
  border-radius: 10px;
  display: none;
}
@media screen and (max-width: 767px) {
  .calendar_parent {
     width: 57%;
  }
}


.pointer {
  cursor: pointer !important;
}
.MuiSnackbarContent-message {
  @include BSC_semi_bold18;
}
.MuiSnackbarContent-root {
  background-color: #dc3545 !important;
}
.option_list_parent {
  position: fixed;
  z-index: 999999;
  right: 15px;
  top: 65px;
  display: none;
}
.profile_icon{
  width: 41px;
  height: 41px;
  background-color: #4c4b4b;
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
}
.profile_icon>img{
  display: block;
  margin: auto;
  margin-top: 10px;
}

.option_list{
  background-color: #d9d9d9;
  border-radius: 5px;
  padding: 15px 20px;
  opacity: 0.9;
}
.option_list>ul>li{
  margin-bottom: 5px;
  margin-top: 5px;
}
.option_list>ul{
  list-style-type: none;
  padding-left: 0px;
  margin: 0px;
  cursor: pointer;
  @include BC_medium20;
}
.option_tri{
  width: 0; 
  height: 0; 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;  
  border-bottom: 10px solid #d9d9d9; 
  margin: auto; 
}
.opt_hr{
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: -20px;
  background-color: #000000;
}
.setting_click,
.setting_click:hover{
  text-decoration: none;
  color: #333333;
  margin-bottom: 5px;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.BS_semi_bold {
  font-family: "Barlow Condensed";
  font-weight: 600;
  font-size: 36px;
}
.BSC_extra_bold18 {
  font-family: "Barlow Semi Condensed";
  font-weight: 800;
  font-size: 18px;
}
.BSC_extra_bold18>th{
  font-size: 20px !important;
}
.BSC_medium {
  font-family: "Barlow Semi Condensed";
  font-weight: 500;
  font-size: 36px;
}
.BSC_extra_bold {
  font-family: "Barlow Semi Condensed";
  font-weight: 800;
  font-size: 30px;
}
.work_sans_regular16 {
  font-family: "Work Sans";
  font-weight: 400;
  font-size: 14px !important;
}
.work_sans_regular14 {
  font-family: "Work Sans";
  font-weight: 400;
}
.BSC_semi_bold18 {
  font-family: "Barlow Semi Condensed";
  font-weight: 600;
  font-size: 18px;
}
.BSC_semi_bold16 {
  font-family: "Barlow Semi Condensed";
  font-weight: 600;
  font-size: 16px;
}
.BSC_semi_bold12 {  
  font-family: "Barlow Semi Condensed";
  font-weight: 600;
  font-size: 12px;
}
.OTPublisherContainer {
  display: none;
}
.enabled-btn,
.enabled-btn:hover,
.enabled-btn:focus{
  background-color: $orange;
  font-weight: 700;
  color: white;
  cursor: pointer;
  font-size: 10px;
}
.disable-btn,
.disable-btn:hover,
.disable-btn:focus{
  background-color : #cccccc;
  color: #909090;
  font-weight: 700;
  cursor: not-allowed;
  pointer-events: none;
  font-size: 10px;
}
