.p_map_view{
    width: 100%;
    height: calc(100% - 60px);
}
.people_report_parent,
.people_visit_parent{
    margin-left: 100px;
    margin-right: 100px;
}
.getmore_enable{
    cursor: pointer;
    pointer-events: auto;
    color : #F0762B;
}
.getmore_disable{
    pointer-events: none;
    color : #cccccc;
}