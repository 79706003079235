@import '../common_style.scss';
.driver_profile{
    height: 81px;
    width: 81px;
    border-radius: 50%;
    margin: auto;
    display: block;
}
.driver_score{
    height: 127%;
    width: 36%;
    float: right;
    background: #f2fcff;
    padding: 45px 0px;
    margin-top: -20px;
    text-align: center;
}
.driver_score>p{
    font-size: 18px;
}
.driver_detail_parent>div{
    margin-bottom: 40px;    
}
.driver_detail_parent>div>h3{
    @include BS_semi_bold;
}
._2OR24XnUXt8OCrysr3G0XI {
    border: none !important;
    background-color: #eeeeee !important;
    padding: 12px !important;
    border-radius: 10px !important;        
    outline: 0 !important;
    font-family: "Barlow Semi Condensed";
    font-weight: 600;
    font-size: 16px;
}
._7ahQImyV4dj0EpcNOjnwA{
    background: transparent !important;
    border: 1px solid #ff3501;
    color: #333333 !important;
    font-size: 15px !important;
}
