// // $orange : #F0762B;
// // @import "../common_style";

// // .navBar{
// //   padding: 18px;
// //   padding-bottom: 0px;
// //   background-color: #000000;
// //   position: fixed;
// //   z-index: 99999;
// //   box-shadow: 0px 0px 10px 2px #424242;
// // }
// // .navBar-align-img{
// //   text-align: left;
// // }
// // .navBar-align-username{  
// //   color: #F0762B;
// //   @include BS_semi_bold;
// //   font-size: 20px;
// // }
// // .navBar-list{
// //   color: #ffffff;
// //   font-weight: bold;
// //   font-size: 16px;
// //   cursor: pointer;
// //   height: 38px;
// // }
// // .navBar-borderBottom{
// //   border-bottom: 5px solid $orange;
// //   color: #F0762B;
// // }
// // .navBar-click{
// //   color: #ffffff;
// //   @include BC_medium20;
// // }
// // .navBar-click:focus{
// //   color: $orange;
// //   text-decoration: none;
// // }
// // .navBar-click:hover{
// //   color: currentColor;
// //   text-decoration: none;
// // }
// // .is-active{
// //   color: $orange;
// // }
// // .is_active{
// //   color: currentColor !important;
// //   text-decoration: none;
// // }
// // .classic_login{
// //   position: absolute;    
// //     bottom: -35px;
// //     background: #0a0a0a;    
// //     padding: 5px 25px;
// //     color: #ffffff;
// //     /* left: 31em; */
// //     left: 0;
// //     border-radius: 0px 0px 10px 10px;
// //     border: 1px solid #4b4b4b;
// // }



/* NavbarComponent.scss */

.navbar-dark {
    background-color: #b9b3b3;
  }
  
  .grid-container {
    display: flex;
    align-items: center;
  }
  
  .nav-links .nav-link {
    font-size: 25px;
  
    color: #fff;
    font-family: "Barlow Condensed";
    margin-left: 40px;
    font-weight: 500;
    transition: color 0.3s ease;
  
    &:hover {
      color: #f90;
    }
  }
  
  .user-nav .nav-link {
    font-size: 20px;
    color: #fff;
    display: flex;
    font-family: "Barlow Condensed";
    font-weight: 600; 
    
    align-items: center;
    transition: color 0.3s ease;
  
    &:hover {
      color: #f90;
    }
  
    img {
      margin-right: 8px;
    }
  }
  
.dropdown-menu { 
	background: linear-gradient( 
		135deg, 
		#52e878, 
		#00b59b
	); 
	border: none; 
}   
.dropdown-item { 
	color: #ffffff !important; 
	font-size: 25px; 
	font-weight: 600; 
} 

.navbar-dark .navbar-nav .nav-link {
    color: #dad1d1;
  }

.navbar-nav .nav-link:hover, 
.dropdown-item:hover { 
	// background: linear-gradient( 
	// 	135deg, 
	// 	#00b59b, 
	// 	#52e878
	// ); 
	color: #ffffff !important; 
	transition: background-color 0.3s 
		ease-in-out; 
} 
.navbar-nav .nav-link.active { 
	// background: linear-gradient( 
	// 	135deg, 
	// 	#ffc400, 
	// 	#ffac00
	// ); 
	color: #f90!important; 
}   

.basic-navbar-nav{
    color: #ffffff !important;  
}




// .navbar-dark {
//   background-color: #b9b3b3;
// }

// .grid-container {
//   display: flex;
//   align-items: center;
// }

// .nav-links .nav-link {
//   font-size: 25px;
//   color: #fff;
//   font-family: "Barlow Condensed";
//   margin-left: 40px;
//   font-weight: 500;
//   transition: color 0.3s ease, background-color 0.3s ease;
//   padding: 5px 10px;
//   border-radius: 5px;

//   &.active, &:hover {
//     color: #fff;
//     background-color: #c00;
//   }
// }

// .user-nav .nav-link {
//   font-size: 20px;
//   color: #fff;
//   display: flex;
//   font-family: "Barlow Condensed";
//   font-weight: 600;
//   align-items: center;
//   transition: color 0.3s ease;

//   &:hover {
//     color: #f90;
//   }

//   img {
//     margin-right: 8px;
//   }
// }

// .dropdown-menu { 
//   background: linear-gradient(135deg, #52e878, #00b59b); 
//   border: none; 
// }   

// .dropdown-item { 
//   color: #ffffff !important; 
//   font-size: 25px; 
//   font-weight: 600; 
// } 

// .navbar-dark .navbar-nav .nav-link {
//   color: #dad1d1;
// }

// .navbar-nav .nav-link:hover, 
// .dropdown-item:hover { 
//   color: #ffffff !important; 
//   transition: background-color 0.3s ease-in-out; 
// } 

// .navbar-nav .nav-link.active { 
//   color: #f90!important; 
// }   

// .basic-navbar-nav{
//   color: #ffffff !important;  
// } 
