@import "../../Component/color_pallet";
@import "../common_style";

.location_img{
    height: 80%;
    width: 30%;
    display: block;
    margin: auto;
}
.create_journey_card{
    height: 100%;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 2px #e4e4e4;
    border: 1px solid #f3f3f3;
    margin-bottom: 25px;
}
.cj_hr{
    margin-left: -20px;
    margin-right: -20px;
}
.drop-hr{
    margin-top: 10px;
    margin-bottom: 0px;
}
.cj_enter_detail_vehicle>div{
    margin-bottom: 40px;
}
.addtional_detail_input{
    border: none;
    background-color: #eeeeee;
    outline: none;
    color: #000000;
    padding: 10px;
    border-radius: 10px;
}
.cj_enter_detail_vehicle{
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 30px;
}
.cj_attach_vehicle{
    background: #eeeeee;
    padding: 30px 100px 30px 100px;    
}
.driver_list{
    display: none;
}
.generate-btn{
    background-image: linear-gradient(#f37425, #fe450c);
    box-shadow: 0px 0px 9px 2px #ffc19c;
    cursor: pointer;
    color: #ffffff !important;
    font-weight: bold;
    text-transform: uppercase;    
    border-radius: 10px !important;
    font-size: 14px !important;
    padding: 20px 15px !important;
}
.back-btn{
    background-color: #ffffff;
    border: 1px solid #F0762B;
    cursor: pointer;
    color: #F0762B;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 7px;
    padding: 20px 60px;
    margin-right: 20px;
}
.back-btn:hover,
.back-btn:focus{
    @extend .back-btn;
    outline: none;
}
.generate-btn:hover,
.generate-btn:focus{
    @extend .generate-btn;
    outline: none;
}
.search_icon{
    position: absolute;
    top: 13px;
    right: 10px;
}
.search_address{
    padding: 15px 0px;
    border-bottom: 1px solid #d6d6d6;
    cursor: pointer;
}
.address_list{
    max-height: 20em;
    overflow: auto;
    height: 700px;
}
.page_header{
    @include BS_semi_bold;
}
.cj_w_header{
    @include work_sans_regular16;
}
.journey_detail_div{
    margin-right: 100px;
    margin-left: 100px;
    height: 100%;
    background-color: #ffffff;    
    border-radius: 10px;
    box-shadow: 0px 0px 10px 2px #f3f3f3;
    border: 1px solid #f3f3f3;
    padding: 30px;
}
.journey_input{
    width: 100%;
    border: none;
    background-color: #e4e4e4;
    padding: 10px;
    padding-right: 35px;
    border-radius: 10px;
    outline: 0;
    height: 40px;
    @include BSC_semi_bold16;
}
.dv_dropdow{
    position: absolute;
    border: 1px solid #ccc;
    width: 94%;
    padding: 10px;
    background: #ffffff;
    z-index: 9;
}
.input_div{
    position: relative;
}
.input_div>img{
    position: absolute;
    top: 10px;
    right: 10px;
}

.btn_div{
    text-align: center;
    margin-top: 3em;
}
.sj_btn,
.sj_btn:hover,
.sj_btn:focus{
    background-image: linear-gradient(#f37425, #fe450c);
    box-shadow: 0px 0px 9px 2px #ffc19c;
    cursor: pointer;
    color: #ffffff;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 10px;
    padding: 20px 10px;    
    margin-top: 10px;
    margin: 0px 10px;
    outline: 0;
    font-size: 15px !important;
}
.cj_btn,
.cj_btn:hover,
.cj_btn:focus{
    background-color: #ffffff;    
    cursor: pointer;
    color: #F0762B;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 10px;
    padding: 20px 10px;    
    margin-top: 10px;
    border: 1px solid #F0762B;
    margin: 0px 10px;
    outline: 0;
}
.journey_detail_div>div>div{
    margin-bottom: 30px;
}
.remove_driver{
    text-align: center;
    font-size: 30px;
    color: #828282;
}

.time_select{
    width: 100%;
    border: 1px solid #c1c1c1;
    background-color: #ffffff;
    padding: 6px 0px;
}
.time_picker{
    position: absolute;
    width: 100%;
    background-color: #ffffff;
    z-index: 999;
    box-shadow: 0px 0px 10px 2px #f3f3f3;
    padding: 5px;    
    display: none;    
}
.close_time_picker{
    cursor: pointer;
    text-align: center;
    color: #F0762B;
    margin-top: 5px;
}

.select_address_parent{
    height: 100%;
    width: 100%;
    position: fixed;
    overflow: auto;
    top: 0;
    background: #00000025;
    z-index: 999;
}
.select_address{
    position: relative;
    margin: 100px;
    background-color: #ffffff;    
}
.enter_address_div{
    position: relative;
    height: 100%;
    padding: 50px;
}
.save_address,
.save_address:focus,
.save_address:hover{
    padding-right: 3em;
    padding-left: 3em;    
    position: absolute;    
    right: 35%;
    bottom: 10%;
}
.jo_header{
    text-align: center;
}
.jo_header>img{
    width: 80px;
    display: block;
    margin: auto;
}
.jo_p{
    margin: 0px;
    padding: 10px;
}
.jo_cancel{
    padding: 7px 10px !important;
    margin-top: 0px !important;
    box-shadow: none !important;
}
.dropdown_div{
    position: absolute;
    z-index: 99;
    background: #ffffff;
    width: 94%;
    border: 1px solid #cccccc;
    padding: 10px;
    display: none;
}
    