// @import "../src//Component//color_pallet.scss";
// @import "../src/Component/common_style.scss";

// .login_parent{
//     background-color: #222222;
//     position: relative;
// }
// .login_header{
//     color: #ffffff;
//     @include BSC_medium;
//     font-weight: bold;
// }
// .textbox_suggestion{
//     color: #c5c5c5;
//     @include work_sans_regular16;
//     display: none;
// }
// .login_details_div{
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);    
//     width: 366px;
//     z-index: 99;
// }
// .show_password{
//     position: absolute;
//     cursor: pointer;
//     bottom: 215px;
//     right: 0;
// }
// .switch_to{
//     @include BSC_semi_bold16;
//     font-size: 14px;
//     color: #f4811f;
//     bottom: 113px;
//     right: 0;
//     position: absolute;    
//     cursor: pointer;
// }
// .login_details_div>input,
// .login_details_div>button,
// .login_details_div>p{
//     margin-bottom: 2.5em;
// }
// .login_details_div>input{
//     @include BSC_medium;
//     font-weight: bold;
//     font-size: 26px;
//     padding: 25px 0px;
//     border: none;
//     box-shadow: none;
//     border-bottom: 1px solid #979797;
//     border-radius: 0px;
//     color: #c5c5c5;
//     background: transparent;
// }
// input:-webkit-autofill {
//     background-color: red !important;
// }
// .login_details_div>input::-webkit-inner-spin-button, 
// .login_details_div>input::-webkit-outer-spin-button { 
//   -webkit-appearance: none; 
//   margin: 0; 
// }
// .login_details_div>input:focus{
//     border: none;
//     box-shadow: none;
//     border-bottom: 1px solid #828282;
//     border-radius: 0px;
// }
// .sign_in_btn{    
//     float: none;
//     display: block;
//     margin: auto;
//     padding-left: 35px;
//     padding-right: 35px;
// }
// .sign_in_info{
//     text-align: center;
//     color: $orange;
//     font-size: 20px;
// }
// .login_bg_img{
//     position: absolute;
//     bottom: 0;
//     right: 0;
//     width: 40%;
//     height: 60%;
// }
// .form-control[disabled]{
//     background-color: transparent !important;
// }
// .customer_care{
//     position: absolute;
//     bottom: 0;
//     right: 0;
//     margin-right: 25px;
//     color: #ffffff;
// }



@import "../src/Component/color_pallet.scss";
@import "../src/Component/common_style.scss";

.login_parent {
  background-color: #222222;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  padding: 20px;
}

.login_header {
  color: #ffffff;
  
  @include BSC_medium;
  font-weight: bold;
  margin-bottom: 1em;
}

.textbox_suggestion {
  color: #c5c5c5;
  @include work_sans_regular16;
  display: none;
}

.login_details_div {
  width: 100%;
  max-width: 366px;
  z-index: 99;
  padding: 20px;
 // background: #f2fcffb0;
  background: #000000;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.show_password {
  position: absolute;
  cursor: pointer;
  bottom: 215px;
  right: 0;
}

.switch_to {
  @include BSC_semi_bold16;
  font-size: 14px;
  color: #f4811f;
  bottom: 113px;
  right: 0;
  position: absolute;
  cursor: pointer;
}

.login_details_div > input,
.login_details_div > button,
.login_details_div > p {
  margin-bottom: 2.5em;
}

.login_details_div > input {
  @include BSC_medium;
  font-weight: bold;
  font-size: 26px;
  padding: 25px 0px;
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #979797;
  border-radius: 0px;
  color: #c5c5c5;
  background: transparent;
  width: 100%;
}

input:-webkit-autofill {
  background-color: red !important;
}

.login_details_div > input::-webkit-inner-spin-button,
.login_details_div > input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.login_details_div > input:focus {
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #828282;
  border-radius: 0px;
}

.sign_in_btn {
  float: none;
  display: block;
  margin: auto;
  padding-left: 35px;
  padding-right: 35px;
}

.sign_in_info {
  text-align: center;
  color: $orange;
  font-size: 20px;
}

.login_bg_img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40%;
  height: 60%;
}

.form-control[disabled] {
  background-color: transparent !important;
}

.customer_care {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 25px;
  color: #ffffff;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .login_details_div {
    width: 90%;
    padding: 15px;
  }

  .login_parent {
    padding: 10px;
  }

  .login_bg_img {
    width: 60%;
    height: 40%;
  }

  .show_password {
    position: absolute;
    cursor: pointer;
    bottom: 215px;
    margin-bottom: 97px;
    right: 58px;
}
}

.logos{
  left: 20px;
}

.show_password {
  position: absolute;
  cursor: pointer;
  bottom: 439px;
  margin-bottom: 97px;
  right: 1007px;
}

