@import "../common_style";


 .notificationbox {
  height: 100%;
  width: 100%;
//  margin-top: 30px;
  height: 100%;
  width: 100%;
 // margin-top: 275px;
 margin-top: 177px;
  

  background-color: #ffffff;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 2px #f3f3f3;
  border: 1px solid #f3f3f3;
}

.noti-div{
  overflow:auto;
  height: 36em;
}
.noti-div:hover{
  overflow: auto;
}
.noti-icon{  
  display: block;
  margin: auto;
  width: 100%;
}
.noti-content>label{
  font-size: 16px;
  @include BSC_semi_bold16;
}
.noti-content>p{
  font-size: 16px !important;  
  @include BSC_medium;
  margin-bottom: 0px;
}
.noti-content>span{
  font-size: 12px !important;
  color: #7b7b7b;
  font-weight: normal;
  @include work_sans_regular14;

}
@media (min-height: 1281px) {

  //CSS

}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
// @media (min-height: 861px) and (max-height: 900px) {
//
//   .noti-div{
//     height: 46vh;
//     overflow:hidden;
//   }
//   .noti-div:hover{
//     overflow: auto;
//   }
//
// }
//
//
// @media (min-height: 801px) and (max-height: 860px) {
//
//   .noti-div{
//     height: 43vh;
//     overflow:hidden;
//   }
//   .noti-div:hover{
//     overflow: auto;
//   }
//
// }
@media (min-height: 768px) and (max-height: 800px) {
.react-tabs__tab{
    padding: 6px 6px !important
  }
  
}
@media screen and (max-width: 767px) {
  .notificationbox {
     // margin-top: 375px;
      margin-top: 416px;
    
  }
}