//*driver settings color code*
$orange: #F0762B;
$grey-notactive-tab:   #808184;
$black-selected-tab: #000000;
$orange-ontab-indicate: #f4811f;
$hex-grey-font-bold-color: #333333;
//Renew -subscription-button-on-driver-setting-page
$bright-ornage-button-color: #ed7d28;
$vivid-red: red;
//* Vehicle Setting page*
//vehicle setting font
$dark-black: #000000;
//font-with-icon-color
$ver-dark-grey:#666666;
//not-active-tab-color
$dark-grayish-blue: #808184;
//indicate-tab-active
$orange-ontab-indicate: #f4811f;
//vehicle-setting-page-save-button
$bright-orange-button-color: #ed7d28 ;
//user-name-on-navbar-color
$Vivid-orange:#f4811f;

//* layout font color
//not-active-tab-color
$dark-grayish-blue:#808184;

//* driver-listing*
//driver-status-available-color
$dark-lime-green: #28a745;   
 // driver-listing-bold-font-color
 $very-dark-gray:#333333;   
 //status-error-color
 $bright-red: #dc3545; 
 
 //servicing-status of vehicle
$dark-blue: #227cd0;
//in-transiest
$light-orange:#f5920d;
//* Summery Reports
//distance-driven
$pale-yellow:#fff0c0;
$Soft-yellow:#fcd763;
$vivid-orange-yellow:#e2a102;
//duration driven
$pale-blue:#c8e3ff;
$cornflower-blue:#59acff;
$dark-blue:#004f9e;
//alert Violation
$dark-grey:#333333;
$orange:#f4811f;
$light-grey:#e2e2e2;
$pale-orange:#ffebd9;
$light-orange:#ffb678;
$vivid-orange:#df6b08;
//vehicles-reports
//ignition-on
$strong-blue:#0029b7;
$vivid-yellow:#ffc817;
$vivid-orange:#f4811f;
$Soft-yellow-green:#92b638;
//* driver-reports
$blue-magenta:#6b00b7;
$Pure-or-mostly-pure-pink:#f3009f;


