
@import "../common_style";

$driverBorder: 1px solid #cccccc;
$primaryColor: #007bff;
$hoverColor: #0056b3;
$backgroundColor: #f0f4f8;
$borderRadius: 8px;
$boxShadow: 0 4px 8px rgba(0, 0, 0, 0.1);

.cardViews {
 //height: 567px;
    height: 653px;
    
    background-color: #ffffff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 2px #f3f3f3;
    border: 1px solid #f3f3f3;
 // margin-top: 42px;
}


// @media screen and (max-width: 768px) {
//   .cardView {
//     margin-top: 358px;
//   }
// }
.widget-header {
  text-transform: uppercase;
  @include BSC_extra_bold18;
  text-align: left;

}
.active_widget_btn {
  background-image: linear-gradient(#5c5958, #fe450c);
  color: #ffffff;
  padding: 16px;
  border-radius: 13px;
}
.inactive_widget_btn {
  color: #828282;
  //border: 1px solid black;
  padding: 16px;
  border-radius: 13px;
}
.dgbuttons{
text-align: right;
 display: flex;
justify-content: center;
  align-items: center;
 
}

.toggle_btn, .button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 768px) {
  .toggle_btn {
    flex-direction: column;
    align-items: flex-end;
  }
  


  .cardViews {
    //height: 567px;
       height: 653px;
       border-radius: 20px;
       box-shadow: 0px 0px 10px 2px #f3f3f3;
       border: 1px solid #f3f3f3;
    // margin-top: 42px;
   }

  .active_widget_btn,
  .inactive_widget_btn {
    margin-bottom: 5px;
  }
}

.dataWidgetDiv {
  height: calc(100% - 116px);
  overflow: hidden;

  &:hover {
    overflow-y: auto;
  }
}
.no_data_map {
  text-align: center;
  line-height: 1.5em;
  font-size: 25px;
  font-weight: 700;
  margin-top: 80px;
}
.orange-btn {
  @include orange-btn;
}

.dataWidgetDiv > ul {
  list-style-type: none;
  text-align: left;
  font-size: 18px;
  margin: 0;
  padding: 0;
}

.dataWidgetDiv > ul > li > label {
  @include BSC_medium;
  font-size: 16px;
}

.dataWidgetDiv > ul > li {
  border-bottom: 1px solid #eeeeee;
  padding: 5px 0;
}
.dataWidgetDiv > ul > li > span {
  @include BSC_medium;
  font-size: 16px;
  font-weight: bold !important;
  float: right;
}
.widget-car-status > ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: table;
  margin-top: 5px;
}
.widget-car-status > ul > li {
  display: table-cell;
  padding: 0 5px;
  text-align: left;
  font-weight: 500;
  color: #7b7b7b;
  cursor: pointer;
}


.widget-mapview {
  height: calc(100% - 68px);
}

.button_parent {
  position: relative;
}

.view_button {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.selected_tab {
  border-bottom: 2px solid $orange;
  color: #000000 !important;
  cursor: pointer;
}

.vehicle_state {
  text-align: center;
  color: #808184;
  @include BSC_semi_bold16;
  cursor: pointer;
}

.zoom_in_map {
  position: absolute;
  top: 50px;
  height: 90%;
  width: 95%;
  left: 35px;
  z-index: 9;
  pointer-events: visible;
}
.map_loading {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background: #00000035;
  z-index: 999999;
  display: none;
  width: 100%;
  height: 100%;
}

.map_loading_icon {
  position: absolute;
  top: 45%;
  right: 50%;
  bottom: 50%;
  left: 45%;
}
.driver_img_div {
  height: 43px;
  width: 43px;
  border-radius: 50%;
  background-color: $orange;
  display: block;
  margin: auto;
  > img {
    width: 100%;
    height: 100%;
    display: block;
    margin: auto;
    padding: 4px;
    border-radius: 50%;
  }
}
.wid_driver_parent {
  height: calc(100% - 75px);
}
.wid_driver_score, .wid_fs, .wid_fs1 {
  padding: 25px 10px;
  font-size: 14px;
  height: 50%;
  align-content: space-evenly;
  text-align: center;

  &.wid_fs1 {
    border-right: $driverBorder;
    border-bottom: $driverBorder;
  }
}
.fs_state {
  text-align: center;
  cursor: pointer;
  margin-top: 15px;
}
.wid_fs_parent, .fs_parent_div {
  height: 100%;
}
.count_style {
  @include work_sans_bold30;
}
.status_vehicle {
  font-size: 16px !important;
  @include BSC_medium;
  text-transform: capitalize;
}
.driver_aval_parent {
  text-align: center;
  height: calc(100% - 115px);
  align-content: space-evenly;
}
.text_right {
  text-align: right;
}

.text_left {
  text-align: left;
}

.center_text {
  text-align: center;
}

.total_fuel {
  position: absolute;
  width: 90%;
  bottom: 12px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 2px 5px;
}
.fuel_table_view {
  position: relative;
  height: calc(100% - 78px);
}
.table_border_top {
  border-top: 1px solid #cccccc;
  padding: 5px;
}

.list_scroll {
  height: calc(100% - 120px);
  overflow-y: auto;
  overflow-x: hidden;
}
.buttons_div{
    margin: 10px auto;
    background-color: #ffffff;
    padding: 14px;
    border-radius: 20px;  
    box-shadow: 0px 0px 10px 2px #f3f3f3;
    border: 1px solid #f3f3f3;
}
.selectordiv-container {
  max-height: 150px; /* Adjust the height as needed */
  overflow-y: auto;
}
.clusterdiv {
 
  border-radius: $borderRadius;
  padding: 0px;
  box-shadow: $boxShadow;
  margin: 10px auto;
  max-width: 300px;

  .widget-header_name {
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }

  .selectordiv {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:focus {
      outline: none;
      border-color: $primaryColor;
      box-shadow: 0 0 5px rgba(241, 71, 71, 0.5);
    }
  }
}
.graph_view_btn{    
  text-transform: uppercase;
  border-radius: 13px;
  box-shadow: 0px 0px 10px 2px #eaeaea;
  width: fit-content;
 
}
.dropdown {
  width: 100%;
  position: relative;
  display: inline-block;
  text-align: center;
  font-weight: 600;
  &-select {
    width: 80%;
    padding: 10px 15px;
    font-size: 16px;
    color: #333;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    font-weight: 600;
    transition: all 0.3s ease;

    &:hover,
    &:focus {
      border-color: #007bff;
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    }
  }
}
.jsw_report_parent,
.jws_report{
    padding-left: 100px;
    padding-right: 100px;    
} 


.all_port{
    margin: 0;
    padding: 5px;
    font-weight: 700;
    color: #fff;
    cursor: pointer;
    background-image: linear-gradient(to bottom, #ed7d28, #ff3501);
}
.legend_text{
    margin-left: 5px;
    margin-bottom: 0px;
    text-align: left;
    margin-top: 3px
}
.legend_1{
    height: 10px;
    width: 10px;
    background-color: green;
}
.legend_2{
    height: 10px;
    width: 10px;
    background-color: yellow;
}
.legend_3{
    height: 10px;
    width: 10px;
    background-color: red;
}
.legend_parent{
    height: 100%;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dedede;
}
.justify_center{
    display: flex;
    justify-content: center;
    align-items: center;
}

.legend_parent1{
    height: 100%;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    }
.statistic_container3 {
  // position: fixed;
  position: absolute;
  z-index: 99999;
  bottom: 5px;
  // right: 120px;
  width: auto;
  margin-bottom: 5px;
  height: auto;
  background: whitesmoke;
  padding: 7px;
  box-shadow: 0px 0px 10px 0px #cccccc;
 // border-radius: 18px;
  text-align: center;
  opacity: 1;
}



@media (max-width: 768px) {
  .statistic_container3 {
    margin-bottom: 405px;

  }

  .endtime{
    margin-left: -201px;
  }
  .calendar_parent2 {
    position: absolute;
    width: 67%;
    margin-left: -134px;
    background-color: #ffffff;
    z-index: 999;
    box-shadow: 0px 0px 10px 2px #f3f3f3;
    padding: 10px;
    border-radius: 10px;
    display: none;
}
}


.legend_inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.legend_item_inline {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.legend_item_inline:last-child {
  margin-right: 0;
}

.legend_4, .legend_77, .legend_5, .legend_9 {
  height: 15px;
  width: 15px;
  margin-right: 5px;
}

.legend_4 {
  background-color: #3326EB;
}

.legend_77 {
  background-color: #eb6c0b;
}

.legend_5 {
  padding-right: 7px;
  background-color: #DE0291;
}

.legend_text_map {
  margin: 0;
}
.statistic_container4 {
  // position: fixed;
  margin-bottom: 194px;
  position: absolute;
  z-index: 99999;
  bottom: 5px;
  width: auto;
 
  height: auto;
  background: whitesmoke;
  padding: 5px;
  box-shadow: 0px 0px 10px 0px #cccccc;
  /* border-radius: 18px; */
  text-align: center;
  opacity: 1;
}
.legend_inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.legend_item_inline {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.legend_item_inline:last-child {
  margin-right: 0;
}
.legend_4 {
  background-color: #3326EB;
}

.legend_77 {
  background-color: #eb6c0b;
}

.legend_9 {
  background-color: rgb(75, 73, 73);
}

.legend_text_map {
  margin: 0;
}


@media only screen and (max-width: 768px) {
  .summary-table, .fse_table, .third_table {
    display: block;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  }

  .summary-table thead, .fse_table thead, .third_table thead {
    display: none;
  }

  .summary-table tr, .fse_table tr, .third_table tr {
    display: block;
    border: 1px solid #ddd;
    margin-bottom: 10px;
  }

  .summary-table td, .fse_table td, .third_table td {
    display: block;
    text-align: right;
    font-size: 14px;
    padding: 8px 8px;
    border-bottom: 1px solid #ddd;
    position: relative;
    padding-left: 50%;
  }

  .summary-table td::before, .fse_table td::before, .third_table td::before {
    content: attr(data-label);
    position: absolute;
    left: 8px;
    width: calc(50% - 16px);
    padding-right: 10px;
    white-space: nowrap;
    font-weight: bold;
    text-align: left;
  }
}

